//Follower setting form component render when Signal provider user clicks on follower settings button.
import "../../css/SignalProvider.scss";
import TitleIcon from "../../assets/g117.svg";
import Back from "../../assets/back.png";
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from "../../assets/info.png";
import { useTranslation } from "react-i18next";


function FollowerSettingForm(props) {


    //-----------------------------------------------------------
    //Declaration of constants, variables and hooks
    //-----------------------------------------------------------

    const [t] = useTranslation("global");
    
    let menu = props.triggerFollowerSettings ? 'open' : 'closed'; //Variable that adopts the value of open or close depending on whether the user has clicked on the follow settings button
    let copyLabel = "";
    let copyText = "";
    let showLoteAmount = "";
    let ddProtection = "";

    //
    
    try{
        //Validations in charge of equalizing the ddProtection variable to show or noShow depending on whether the correct type of copy 
        //mode has been selected
        if (props.followerData.drawdown_mode === "InEquity") {       
            ddProtection = "show";
        }
        else if(props.followerData.drawdown_mode === "NoDrawdown") {
            ddProtection = "noShow"
        };
        console.log(props.followerData.copy_mode);

        //Validation to decide whether or not to show the max and min lots size fields, also change the label of the input by the type 
        //of copy mode
        if (props.followerData.copy_mode === "Proportional") {
            copyLabel = t("follower.Risk Radio");
            copyText = t("follower.Proportion of open lots depending on the signal provider account");
            showLoteAmount = "show";
        }
        else if(props.followerData.copy_mode === "FixedLots"){
            copyLabel = t("follower.Fixed lot amount");
            copyText = t("follower.Fixed value of lots opened by the account in the case of copying a ticket from the signal provider account");
            showLoteAmount = "noShow";
        };
    }catch(error){

    };
    

    return (
        <div className={`                     
                      ${'formContainer-' + menu}
                  `}>
            <form className='settingForm' id="settingForm" >
                <div className='titleContainer'>
                    <div className='titleSetting'>
                        <div></div>
                        <img
                            className='imageTitle'
                            src={TitleIcon}
                            alt="title"
                            htmlFor="title-icon"
                        ></img>
                        <title>{t("signalProvider.Follower Settings")}<br/>Login: {props.idFollower}</title>                        
                    </div>
                    <button className='backIcon' type='button' onClick={props.onClose}>
                        <img
                            src={Back}
                            alt="back Icon"
                            htmlFor="back-icon" />
                    </button>
                </div>                
                <div>
                <div className='labelField'>                        
                        <label className='settingLabel'>{t("login.Login")}</label>
                        <Tooltip title={t("follower.User identification in Metatrader 4")}>
                        <img
                            className='imageTitle'
                            src={InfoIcon}
                            alt="info"
                            htmlFor="info-icon"
                            data-tip data-for="infoTooltip"
                            ></img>
                        </Tooltip>
                    </div>  
                    <input
                        className='input'
                        type="number"
                        id="user-name"
                        name="user-name"
                        autoComplete="on"
                        value={props.idFollower}
                        disabled
                    ></input>
                </div>
                <div className='labelField'>
                    <label className='settingLabel'>{t("follower.Copy Mode")}</label>
                    <Tooltip title={t("follower.Method of copying trades opened by the signal provider account")}>
                        <img
                            className='imageTitle'
                            src={InfoIcon}
                            alt="info"
                            htmlFor="info-icon"
                            data-tip data-for="infoTooltip"
                        ></img>
                    </Tooltip>
                </div> 
                <input
                    className='input'
                    type="email"
                    id="user-name"
                    name="user-name"
                    autoComplete="on"
                    value={props.followerData.copy_mode}
                    disabled
                ></input>
                <div>
                <div className='labelField'>
                        <label className='settingLabel'>{copyLabel}</label>
                        <Tooltip title={ copyText }>
                            <img
                                className='imageTitle'
                                src={InfoIcon}
                                alt="info"
                                htmlFor="info-icon"
                                data-tip data-for="infoTooltip"
                            ></img>
                        </Tooltip>
                    </div> 
                    <input
                        className='input'
                        type="email"
                        id="user-name"
                        name="user-name"
                        autoComplete="on"
                        value={props.followerData.risk_ratio}
                        disabled
                    ></input>
                </div>               
                <div className={`dinamicInput ${showLoteAmount}`}>
                <div className='labelField'>
                        <label className='settingLabel'>{t("follower.Min Lote Size")}</label>
                        <Tooltip title={t("follower.Minimum number of open lots when copying a trade")}>
                            <img
                                className='imageTitle'
                                src={InfoIcon}
                                alt="info"
                                htmlFor="info-icon"
                                data-tip data-for="infoTooltip"
                            ></img>
                        </Tooltip>
                    </div> 
                    <input
                        className='input'
                        type="email"
                        id="user-name"
                        name="user-name"
                        autoComplete="on"
                        value={props.followerData.min_lot_size}
                        disabled
                    ></input>
                </div>
                <div className={`dinamicInput ${showLoteAmount}`}>
                <div className='labelField'>
                        <label className='settingLabel'>{t("follower.Max Lote Size")}</label>
                        <Tooltip title={t("follower.Maximum number of open lots when copying a trade")}>
                            <img
                                className='imageTitle'
                                src={InfoIcon}
                                alt="info"
                                htmlFor="info-icon"
                                data-tip data-for="infoTooltip"
                            ></img>
                        </Tooltip>
                    </div>
                    <input
                        className='input'
                        type="email"
                        id="user-name"
                        name="user-name"
                        autoComplete="on"
                        value={props.followerData.max_lot_size}
                        disabled
                    ></input>
                </div> 
                <div className='labelField'>
                    <label className='settingLabel'>{t("signalProvider.Drawdown Protection")}</label>
                    <Tooltip title={t("follower.Drawdown protection method")}>
                        <img
                            className='imageTitle'
                            src={InfoIcon}
                            alt="info"
                            htmlFor="info-icon"
                            data-tip data-for="infoTooltip"
                        ></img>
                    </Tooltip>
                </div>
                <input
                    className='input'
                    type="email"
                    id="user-name"
                    name="user-name"
                    autoComplete="on"
                    value={props.followerData.drawdown_mode}
                    disabled
                ></input>
                <div className={`dinamicInput ${ddProtection}`}>
                    <div className='labelField'>
                        <label className='settingLabel'>{t("signalProvider.Max Drawdown")}</label>
                        <Tooltip title={t("follower.Maximum value for account Drawdown")}>
                            <img
                                className='imageTitle'
                                src={InfoIcon}
                                alt="info"
                                htmlFor="info-icon"
                                data-tip data-for="infoTooltip"
                            ></img>
                        </Tooltip>
                    </div> 
                    <input
                        className='input'
                        type="email"
                        id="user-name"
                        name="user-name"
                        autoComplete="on"
                        value={props.followerData.max_drawdown}
                        disabled
                    ></input>
                </div>             
            </form>
        </div>
    );

}

export default FollowerSettingForm;