//SidebarFollower component render when the follower component validates if the user type is correct, this component contains all the
//endpoints requests and the most of the follower logic. This components has the task to send the different hooks and function via props
import React, { useState, useEffect } from "react";
import '../../css/SignalProvider.scss';
import FollowerSignalTable from "./Follower_Signal_Table";
import FollowerLogTable from "./Follower_Log_Table";
import FollowForm from "../Follower/followSettings";
import logo from "../../assets/Group 4.svg";
import PersonIcon from "../../assets/Group 29.svg";
import reload from "../../assets/re load icon.svg";
import InfoIcon from "../../assets/info.png";
import Switch from "react-switch";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useInterval } from "../../config/useInterval";
import swal from "sweetalert2";
import SettingForm from "./followerSettings";
import Tooltip from '@mui/material/Tooltip';
import followerService from "../../services/follower";
import userService from "../../services/user";
import Cookies from "universal-cookie";
import Loading from "../gadgets/loading";
import { baseEnv } from "../../App";


function FollowerSidebar() {

    //-----------------------------------------------------------
    //Declaration of constants, variables and hooks
    //-----------------------------------------------------------

    const [leftOpen, setLeftOpen] = useState(true);
    const navigate = useNavigate();
    const [t] = useTranslation("global");
    const [trig, setTrig] = useState(false); 
    const [historyOpen, setHistoryOpen] = useState(false);
    const [followerStatus, setFollowerStatus] = useState({});
    const [followerData, setFollowerData] = useState({});
    const [notFollowing, setNotFollowing] = useState("");
    const [loading, setLoading] = useState(false);
    const [errorMes, setErrorMes] = useState(null);
    const [signalList, setSignalList] = useState("");
    const [totalRows, setTotalRows] = useState(0);
    const [quantityPerPage, setQuantityPerPage] = useState(10);
    const [signalOpen, setSignalOpen] = useState(true);
    const [historicalList, setHistoricalList] = useState("");
    const [openFollowModal, setOpenFollowModal] = useState(true);
    const [signalId, setSignalId] = useState("");
    const [ip, setIp] = useState("");
    const [page, setPage] = useState(1);
    const [totalHistoricalRows, setTotalHistoricalRows] = useState(0);
    const [quantityHistoricalPerPage, setQuantityHistoricalPerPage] = useState(10);
    const [pageHistorical, setHistoricalPage] = useState(1);
    const [periodicFlag, setPeriodicFlag] = useState(false);
    const [nonPeriodicFlag, setNonPeriodicFlag] = useState(false);
    const cookies = new Cookies();
    const user = cookies.get("user");
    const token = cookies.get("token");
    let headers = { //Headers preparation, the token is get from cookies, the same token obtained when the user log in
        "Content-type": "application/json; charset=UTF-8",
        "Authorization": "Bearer " + token
    };
    let leftOpen2 = leftOpen ? 'open' : 'closed';
    let followerOn = signalOpen ? 'ON' : 'OFF';
    let historyOn = !signalOpen ? 'ON' : 'OFF';
    let title = "";

    const platform = cookies.get("platform");;
    if (platform === "mt4") {
        title = "follower.FOLLOWER TERMINAL MT4";
    }
    else if (platform === "mt5") {
        title = "follower.FOLLOWER TERMINAL MT5";
    }

    //-----------------------------------------------------------
    //Requests area
    //-----------------------------------------------------------

    //Function to get the client ip, this ip is used when the follower user changes a setting data.
    const getIp = () => {
        setLoading(true);  //Starts loading spinning
        userService.getIpClient( //Calls the method getIpClient from user service (axios request)
        )
        .then(response=>{ //Waits for a response from the axios request
            setLoading(false);  //Stops loading spinning        
            return response;
        })
        .then(response=>{
            if(response !== null){ 
                setIp(response.data.ip); //If response is correct, sets ip into the hook ip.
            }
            else{
                console.log("not ip")
            }           
        })
        .catch(error => {
            setLoading(false); //Stops loading spinning  
            console.log(error)
        });
    };

    function wait(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    //main function to call sequentially every "get" Endpoint in follower component
     const LoadFollowerData = async (periodically) => {
        if(periodically === false){
            setLoading(true);
            if(periodicFlag === false){
                setNonPeriodicFlag(true);
            }
            else{
                await wait(1000);
                setPeriodicFlag(false);
            }           
        }
        //Validates if there is a request working in this moment and if so, the periodical request is skip
        if(periodically === true && nonPeriodicFlag === true){
            return;
        }
        else{
            setPeriodicFlag(true);
        }
         //Starts loading spinning, validates if the petition is periodic
        userService.getStatus( //Calls the method getStatus from user service (axios request), its passed the headers
            headers
        )
        .then(response=>{  //Waits for a response from the axios request           
            return response.data;
        })
        .then(response=>{
            LoadFollowerDataSettings(periodically, true); //Calls de function loadFollowerDataSettings once finished the first request
            if(response.data != null){ 
                setFollowerStatus(response.data); //If response is correct, sets ip into the hook ip.
            }
            else{
                console.log("not data")
            }           
        })
        .catch(error => {
            if(error.response.data.message === "Login is required"){
                AlertMessage("follower.Session finished, please log in again", "error", "red");
                setNonPeriodicFlag(false);
                setPeriodicFlag(false);
                if(!periodically) setLoading(false);
                console.log(error);
                navigate(baseEnv + "/");
            }
            else{
                periodically === false ? setErrorMes(true) : setErrorMes(false); //Default error message, validates if it's a periodical request
                setNonPeriodicFlag(false);
                setPeriodicFlag(false);
                if(!periodically) setLoading(false); //Stops loading spinning  
                console.log(error);
            }
            
        });                               
    };

    //Request function to get the follower settings data from de communitraders server
    const LoadFollowerDataSettings = async (periodically, chained) => {
        if(periodically === false){
            setLoading(true); //Starts loading spinning
            if(periodicFlag === false){
                setNonPeriodicFlag(true);
            }
            else if(chained === false){
                await wait(1000);
                setNonPeriodicFlag(true);
            }
            userService.getInformation( //Calls the method getInformation from user service (axios request), its passed the headers
                headers
            )
            .then(response=>{ //Waits for a response from the axios request
                return response.data;
            })
            .then(response=>{               
                if(response.status === "Success"){ 
                    if(chained === true){
                        LoadSignalList(quantityPerPage, page, periodically, true); //Calls de function LoadSignalList once finished the first request
                    }
                    else{
                        setNonPeriodicFlag(false);
                        setPeriodicFlag(false);
                        if(!periodically) setLoading(false); //Stops loading spinning
                    }
                    setFollowerData(response.data); //If response is correct, sets ip into the hook ip.
                    setNotFollowing(response.data.master_account); //If response is correct, sets ip into the hook ip.
                }
                else{
                }
            })
            .catch(error => {
                if(chained === true){
                    LoadSignalList(quantityPerPage, page, periodically, true); //Calls de function LoadSignalList once finished the first request
                }
                else{
                    setNonPeriodicFlag(false);
                    setPeriodicFlag(false);
                    if(!periodically) setLoading(false); //Stops loading spinning 
                }
                //LoadSignalList(quantityPerPage, page, periodically);
                if(error.response.data.message === "The account does not have a link with a signal"){
                    setFollowerData("");                            
                    console.log("Follower account does not follow any signal provider");
                }
                else if(error.response.data.message === "Login is required"){
                    AlertMessage("follower.Session finished, please log in again", "error", "red");
                    setNonPeriodicFlag(false);
                    setPeriodicFlag(false);
                    setLoading(false); //Stops loading spinning 
                    console.log(error);
                    navigate(baseEnv + "/");
                }
                else{
                    if(!periodically) AlertMessage("signalProvider.Server error, try again", "error", "red"); //Specific error message                    
                    console.log(error);
                }    
            }); 
        }
        else{
            LoadSignalList(quantityPerPage, page, periodically, true); //Calls de function LoadSignalList once finished the first request
        }
                                     
    };

    //Request function to get the available Signal providers list from de communitraders server
    const LoadSignalList = async (quantity, page, periodically, chained) => {
        if(periodically === false){
            setLoading(true);
            if(periodicFlag === false){
                setNonPeriodicFlag(true);
            }
            else if(chained === false){
                await wait(1000);
                setNonPeriodicFlag(true);
            }           
        }
        //periodically === false ? setLoading(true) : setLoading(loading); //Starts loading spinning, validates if the petition is periodic
        followerService.getMasters( //Calls the method getMasters from follower service (axios request), its passed the headers and pagination
            quantity,
            page,
            headers
        )
        .then(response=>{ //Waits for a response from the axios request
            return response.data;
        })
        .then(response=>{
            if(chained === true){
                LoadFollowerHistoricalSettings(quantityHistoricalPerPage, pageHistorical, periodically, true); //Calls de function LoadFollowerHistoricalSettings once finished the first request
            }
            else{
                setNonPeriodicFlag(false);
                setPeriodicFlag(false);
                if(!periodically) setLoading(false); //Stops loading spinning 
            }       
            if(response.status === "Success"){ 
                setSignalList(response.data.list); //If response is correct, sets ip into the hook ip.
                setTotalRows(response.data.total_registers); //If response is correct, sets ip into the hook ip.
            }
            else{
                if(!periodically) setLoading(false); //Stops loading spinning   
                console.log(response);
            }
        })
        .catch(error => {
            if(error.response.data.message === "Login is required"){
                AlertMessage("follower.Session finished, please log in again", "error", "red");
                setNonPeriodicFlag(false);
                setPeriodicFlag(false);
                if(!periodically) setLoading(false); //Stops loading spinning  
                console.log(error);
                navigate(baseEnv + "/");
            }
            else{
                if(chained === true){
                    LoadFollowerHistoricalSettings(quantityHistoricalPerPage, pageHistorical, periodically, true); //Calls de function LoadFollowerHistoricalSettings once finished the first request
                }
                else{
                    setNonPeriodicFlag(false);
                    setPeriodicFlag(false);
                    if(!periodically) setLoading(false); //Stops loading spinning  
                }
                if(!periodically) AlertMessage("signalProvider.Server error, try again", "error", "red"); //Specific error message
                console.log(error);
            }               
            }         
        );                              
    };

    //Request function to get the historical settings list from de communitraders server
    const LoadFollowerHistoricalSettings = async (quantity, page, periodically, chained) => {
        if(periodically === false){
            setLoading(true);
            if(periodicFlag === false){
                setNonPeriodicFlag(true);
            }
            else if(chained === false){
                await wait(1000);
                setNonPeriodicFlag(true);
            }           
        }
        //periodically === false ? setLoading(true) : setLoading(loading); //Starts loading spinning, validates if the petition is periodic
        followerService.followerHistoricalSettings( //Calls the method followerHistoricalSettings from follower service (axios request),  its passed the headers and pagination
            quantity,
            page,
            headers
        )
        .then(response=>{ //Waits for a response from the axios request
            if(!periodically) setLoading(false); //Stops loading spinning   
            return response.data;
        })
        .then(response=>{
            if(response.status === "Success"){ 
                if(!periodically) setLoading(false); //Stops loading spinning 
                if(periodically) console.log("Periodic Status Ok");
                setNonPeriodicFlag(false);
                setPeriodicFlag(false); 
                setHistoricalList(response.data.list); //If response is correct, sets ip into the hook ip.
                setTotalHistoricalRows(response.data.total_registers); //If response is correct, sets ip into the hook ip.
            }
            else{
                if(!periodically) setLoading(false); //Stops loading spinning  
                if(!periodically) AlertMessage("signalProvider.Server error, try again", "error", "red"); 
                console.log("Endpoint get historical list status OK but not info"); 
                console.log(response);
            }
        })
        .catch(error => {
                if(error.response.data.message === "Login is required"){
                    AlertMessage("follower.Session finished, please log in again", "error", "red");
                    setNonPeriodicFlag(false);
                    setPeriodicFlag(false);
                    if(!periodically) setLoading(false); //Stops loading spinning  
                    console.log(error);
                    navigate(baseEnv + "/");
                }
                else{
                    if(!periodically) setLoading(false); //Stops loading spinning   
                    if(!periodically) AlertMessage("signalProvider.Server error, try again", "error", "red"); //Specific error message, validates if it's a periodic request
                    setNonPeriodicFlag(false);
                    setPeriodicFlag(false);
                    console.log(error);
                }
            }         
        );                              
    };

    //Request function to start following a Signal provider
    const followSignal = async (id, masterFollowSettings) => {
        setLoading(true); //Starts loading spining
        if(periodicFlag === false){
            setNonPeriodicFlag(true);
        }
        else{
            await wait(1000);
            setNonPeriodicFlag(true);
        }                 
        followerService.follow( //Calls the method follow from follower service (axios request), its passed the headers and params
            {
                master_account: id,
                ip_address: ip
            },
            headers
        )
        .then(response=>{ //Waits for a response from the axios request
            return response.data;
        })
        .then(response=>{
            if(response.status === "Success"){
                setOpenFollowModal(true); 
                UpdateFollowerSettings(masterFollowSettings, "");
                swal.fire({
                    title: t("follower.Following"),
                    text: t("follower.You are following Signal Provider ") + id,
                    icon: 'success',
                    confirmButtonColor: "#3085d6"
                }                   
                );
            }
            else{
                setLoading(false); //Stops loading spinning  
                AlertMessage("signalProvider.Server error, try again", "error", "red"); //Specific error message 
            }
        })
        .catch(error => {
                if(error.response.data.message === "Login is required"){
                    AlertMessage("follower.Session finished, please log in again", "error", "red");
                    setNonPeriodicFlag(false);
                    setLoading(false); //Stops loading spinning 
                    console.log(error);
                    navigate(baseEnv + "/");
                }
                else{
                    setLoading(false); //Stops loading spinning 
                    setNonPeriodicFlag(false); 
                    console.log(error);
                    AlertMessage("signalProvider.Server error, try again", "error", "red");  //Specific error message
                }
            }         
        );                              
    };

    const followSignalCustom = (id) => {
        setOpenFollowModal(false);
        setSignalId(id);
    };

    const followModal = (e) =>{
        setOpenFollowModal(e);
    }

    //Request function to left following a Signal provider
    const stopFollowSignal = async (id) => {
        if(periodicFlag === false){
            setNonPeriodicFlag(true);
        }
        else{
            await wait(1000);
            setPeriodicFlag(false);
        }   
        setLoading(true); //Starts loading spining
        followerService.stopFollowing( //Calls the method stopFollowing from follower service (axios request), its passed the headers
            headers
        )
        .then(response=>{ //Waits for a response from the axios request
            setLoading(false); //Stops loading spinning  
            return response.data;
        })
        .then(response=>{
            if(response.status === "Success"){  
                setNonPeriodicFlag(false);
                setNotFollowing("");
                LoadFollowerData(false);
                swal.fire({
                    title: t("follower.Unfollowing"),
                    text: t("follower.Unfollowed signal provider ") + id,
                    icon: 'success',
                    confirmButtonColor: "#3085d6"
                }                   
                );
            }
            else{
                setLoading(false); //Stops loading spinning  
                setNonPeriodicFlag(false);
                AlertMessage("signalProvider.Server error, try again", "error", "red");  //Specific error message
            }
        })
        .catch(error => {
                if(error.response.data.message === "Login is required"){
                    AlertMessage("follower.Session finished, please log in again", "error", "red");
                    setNonPeriodicFlag(false);
                    setLoading(false); //Stops loading spinning 
                    console.log(error);
                    navigate(baseEnv + "/");
                }
                else{
                    setLoading(false); //Stops loading spinning  
                    setNonPeriodicFlag(false);
                    AlertMessage("signalProvider.Server error, try again", "error", "red"); //Specific error message
                    console.log(error);
                }
            }         
        );                              
    };

    //Request function to update the follower settings
    const UpdateFollowerSettings = async (params, message) => {
        setLoading(true); //Starts loading spining
        if(periodicFlag === false){
            setNonPeriodicFlag(true);
        }
        else{
            await wait(1000);
            setNonPeriodicFlag(true);
        }         
        followerService.editFollower( //Calls the method editFollower from follower service (axios request), its passed the headers and params
            headers,
            params
        )
        .then(response=>{ //Waits for a response from the axios request
            return response.data;
        })
        .then(response=>{
            if(response.status === "Success"){                 
                LoadFollowerData(false);  
                setFollowerData(response.data); //If response is correct, sets ip into the hook ip.
                if(message !== ""){
                    AlertMessage(message, "success", "green");
                }               
            }
            else{
                setLoading(false); //Stops loading spinning  
                setNonPeriodicFlag(false);
                AlertMessage("signalProvider.Server error, try again", "error", "red"); //Specific error message
            }
        })
        .catch(error => {
            if(error.response.data.message === "Login is required"){
                AlertMessage("follower.Session finished, please log in again", "error", "red");
                setNonPeriodicFlag(false);
                setLoading(false); //Stops loading spinning 
                console.log(error);
                navigate(baseEnv + "/");
            }
            else{
            setLoading(false); //Stops loading spinning  
            setNonPeriodicFlag(false);
            console.log(error);
            AlertMessage("signalProvider.Server error, try again", "error", "red"); //Specific error message
            }
        });                              
    };
    
    //-----------------------------------------------------------
    //Functions and validations area
    //-----------------------------------------------------------

    //Function handler that re calls the method to consult the list of Available Signal providers with a new number of rows per page
    const handlePerRowsChange = (newPerPage, page) => {
        LoadSignalList(newPerPage, page, false, false);
		setQuantityPerPage(newPerPage);
	};

    //Function handler that re calls the method to consult the list of Available Signal providers with a new number of page
    const handlePageChange = page => {
        LoadSignalList(quantityPerPage, page, false, false);
        setPage(page);  
	};

    //Function handler that re calls the method to consult the list of historical settings with a new number of rows per page
    const handlePerRowsHistoricalChange = (newPerPage, page) => {
        LoadFollowerHistoricalSettings(newPerPage, page, false, false);
		setQuantityHistoricalPerPage(newPerPage);
	};

    //Function handler that re calls the method to consult the list f historical settings with a new number of page
    const handlePageHistoricalChange = page => {
        LoadFollowerHistoricalSettings(quantityHistoricalPerPage, page, false, false);
        setHistoricalPage(page);  
	};

    //Validates if the message state was true and then throw a modal message error
    if(errorMes === true){
        swal.fire({
            text: t("signalProvider.Server error, try again"),
            icon: "error",
            iconColor: "red",
            color: "#363636",
            background: "#FFFFFF",
            confirmButtonColor: "#FF0000"
        });
        setErrorMes(false);
    };

    //Function handler triggers when the follower change the copy mode switch, it function calls the request function to update follower data
    function handleChange() {
        if (followerData.connect) {
            swal.fire({
                title: t("follower.Are you sure to disable Copy mode?"),
                text: t("follower.Copy mode off"),
                icon: "warning",
                iconColor: "orange",
                color: "#363636",
                background: "#FFFFFF",
                showCancelButton: true,
                cancelButtonText: t("signalProvider.Cancel"),
                cancelButtonColor: "#3085d6",
                confirmButtonText: t("signalProvider.Continue"),
                confirmButtonColor: "#FF0000"
            }).then((result) => {
                if (result.isConfirmed) {
                    UpdateFollowerSettings({
                        connect: false                        
                    }, "follower.Copy mode deactivated");                    
                } else if (result.dismiss) {
                    swal.fire({
                        title: t("follower.Cancelled"),
                        icon: 'error',
                        confirmButtonColor: "#3085d6"
                    })
                }
            });
        }
        else {
            swal.fire({
                title: t("follower.Are you sure to enable the Copy Mode?"),
                text: t("follower.Copy Mode on"),
                icon: "warning",
                iconColor: "orange",
                color: "#363636",
                background: "#FFFFFF",
                showCancelButton: true,
                cancelButtonText: t("signalProvider.Cancel"),
                cancelButtonColor: "#3085d6",
                confirmButtonText: t("signalProvider.Continue"),
                confirmButtonColor: "#FF0000"
            }).then((result) => {
                if (result.isConfirmed) {
                    UpdateFollowerSettings({
                        connect: true,                        
                    }, "follower.Copy mode activated");                    
                } else if (result.dismiss) {
                    swal.fire({
                        title: t("follower.Cancelled"),
                        icon: 'error',
                        confirmButtonColor: "#3085d6"
                    })
                }
            });
        }
    };

    // Message function, this function is used for others function to show modal success and error messages
    function AlertMessage(message, icon, color) {     
        console.log("Hola 2");  
        swal.fire({
            text: t(message),
            icon: icon,
            iconColor: color,
            color: "#363636",
            background: "#FFFFFF",
            confirmButtonColor: "#FF0000"
        });
    };

    //Function handler triggered when the follower users press the different option to move between signal provider list, historical and settings components.
    function toggleSidebar() {
        setLeftOpen(!leftOpen);
    };

    //Function handler triggered when user presses the button log out, it sends directly to login view.
    async function logOut() {
        setLoading(true);
        if(periodicFlag === false){
            setNonPeriodicFlag(true);
        }
        else{
            await wait(1000);
            setNonPeriodicFlag(true);
        } 
        userService.logOut( //Calls the method stopFollowing from follower service (axios request), its passed the headers
            headers
        )
        .then(response=>{ //Waits for a response from the axios request
            return response.data;
        })
        .then(response=>{
            setLoading(false);
            setNonPeriodicFlag(false);
            if(response.status === "Success"){                   
                console.log("Log out Successful");
            }
            else{
                console.log("Log out without response");
            }
        })
        .catch(error => {
                setLoading(false);
                setNonPeriodicFlag(false);
                console.log(error);
            }         
        ); 
        navigate(baseEnv + "/");
    }

    //Function handler triggered when follower user presses on settings button, this allows to deploy the settigns sidebar and reload follower settings data
    function triggerSettings() {
        if (window.innerWidth <= 550) { //Responsive adjust, if so, this hidden the main sidebar
            setLeftOpen(false);
        };
        if(!trig){
            LoadFollowerDataSettings(false, false);
        } ;
        setTrig(!trig);
         
    } 

    //Function handlers triggered when follower user presses on Signal Provider list or Historical List, this function controls what components is rendered
    function triggerTables(table) {
        if (window.innerWidth <= 550) {
            setLeftOpen(false);
        }
        if (table === "user") {
            setSignalOpen(true);
            setHistoryOpen(false);
        }
        else if (table === "history") {
            setHistoryOpen(true); 
            setSignalOpen(false);
        }
               
    }

    //Function handler triggered when follower user presses on close setting modal button, and allows to close the setting sidebar
    function settingCloseHandle() {
        setTrig(false);
    }
    
    useEffect(() => {
        var noFollowing = document.getElementById("userLogin");
        try{
            if (followerData.master_account){          
                noFollowing.removeAttribute("hidden");
            }
            else{
                noFollowing.setAttribute("hidden", "true");
            }
        }catch{
            noFollowing.setAttribute("hidden", "true");
        }
    }, [followerData]);
    /*try{
        if (followerData.master_account !== null){          
            noFollowing.removeAttribute("hidden");
        }
    }catch{
        console.log("Ome");
        //noFollowing.setAttribute("disabled", "");
    }*/
    

    //-------------------------------------------------------------------------------------------
    //Periodically request
    //-------------------------------------------------------------------------------------------

    //USe Effect hook triggered once per render, it calls the main request function, which gets all the follower information
    useEffect(() => {
        getIp();
        LoadFollowerData(false);
    }, []);

    //use Effect hook triggered every time the adjusted time is reached, it calls the main request function to refresh periodically all the follower data
    useInterval(() => {
        LoadFollowerData(true);
    }, 10000)


    return (
        <div id='layout'>
            
            <Loading loading={loading}/>
            <div id='left' className={leftOpen2} >
                <div className='icon'
                    onClick={toggleSidebar} >
                    &equiv;
                </div>
                <div className={`sidebar ${leftOpen2}`} >
                    <div className='header'>
                        <img
                            className='imageTitle'
                            src={logo}
                            alt="Logo"
                            htmlFor="logo-image"
                        ></img>
                    </div>
                    <div className='content'>
                        <div className='userData'>
                            {t(title)}
                            <div className='userLogin'>
                                <img
                                    className='imageLogin'
                                    src={PersonIcon}
                                    alt="Person Icon"
                                    htmlFor="person-icon"
                                ></img>
                                <div>
                                {followerStatus.name != null ? followerStatus.name : "No data"}<br></br><font color="gray">{user}</font>
                                </div>
                            </div>
                            <div className='userLogin' id="userLogin">
                                {t("follower.Copy Status")}
                                <Switch
                                    className="react-switch"
                                    checked={followerData.connect != null ? followerData.connect : false}
                                    onChange={handleChange}
                                    offColor="#B71C1C"
                                    onColor="#43A047"
                                    onHandleColor="#FAFAFA"
                                    offHandleColor="#FAFAFA"
                                    handleDiameter={15}
                                    uncheckedIcon={false}
                                    checkedIcon={false}
                                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                    height={15}
                                    width={30}
                                    id="material-switch"
                                ></Switch>
                            </div>
                        </div>
                        <div className='userSettings'>
                            <div className='labelField'>
                                <button className={`
                                        setting
                                        ${followerOn}
                                    `} onClick={(e) => triggerTables('user')}> {t("follower.Signal Providers")} </button>
                                <Tooltip title={t("follower.List of Signal providers available to follow")}>
                                    <img
                                        className='imageTitle'
                                        src={InfoIcon}
                                        alt="info"
                                        htmlFor="info-icon"
                                        data-tip data-for="infoTooltip"
                                    ></img>
                                </Tooltip>
                            </div>
                            <div className='labelField'>
                                <button className= "setting"
                                         onClick={triggerSettings}> {t("signalProvider.My Settings")} </button>
                                <Tooltip title={t("follower.Follower account settings menu")}>
                                    <img
                                        className='imageTitle'
                                        src={InfoIcon}
                                        alt="info"
                                        htmlFor="info-icon"
                                        data-tip data-for="infoTooltip"
                                    ></img>
                                </Tooltip>
                            </div>
                            <div className='labelField'>
                                <button className={`
                                        setting
                                        ${historyOn}
                                    `} onClick={(e) => triggerTables('history')}> {t("signalProvider.Historical Settings")} </button>
                                <Tooltip title={t("follower.Log of settings made by the Follower user")}>
                                    <img
                                        className='imageTitle'
                                        src={InfoIcon}
                                        alt="info"
                                        htmlFor="info-icon"
                                        data-tip data-for="infoTooltip"
                                    ></img>
                                </Tooltip>
                            </div>
                            <button className='setting' onClick={logOut}> {t("signalProvider.Sign Out")} </button>                               
                        </div>
                    </div>
                </div>
            </div>

            <div className={`
                      main
                      ${'left-' + leftOpen2}
                  `} id='main'>
                <div className='header'>
                    <h3 className={`
                      title
                      ${'left-' + leftOpen2}
                  `}>
                        <div className='statusData'>
                            <div>
                                {followerStatus.name != null ? followerStatus.name : "No data"}
                            </div>
                            <div>
                                {t("signalProvider.Balance")}   {followerStatus.balance != null ? followerStatus.balance : "no balance"}
                            </div>
                            <div>
                                {t("signalProvider.Equity")}    {followerStatus.equity != null ? followerStatus.equity : "no equity"}
                            </div>
                        </div>
                        <button className='reloadIcon' onClick={(e) => LoadFollowerData(false)}>
                            <img
                                src={reload}
                                alt="reload Icon"
                                htmlFor="reload-icon" />
                        </button>
                    </h3>
                </div>
                <div className='contentMain'>   
                    <FollowForm
                        followerData={followerData} 
                        setLoading={setLoading} 
                        ip={ip}
                        openFollowModal={openFollowModal}
                        followSignal={followSignal}
                        signalId={signalId}
                        followModal={followModal}
                    />                                            
                    <SettingForm 
                    triggerMenu={trig} 
                    onClose={settingCloseHandle} 
                    followerData={followerData} 
                    setLoading={setLoading} 
                    ip={ip}
                    UpdateFollowerSettings={UpdateFollowerSettings}
                    />
                    <FollowerSignalTable 
                    triggerUser={signalOpen} 
                    signalList={signalList} 
                    totalRows={totalRows} 
                    handlePerRowsChange={handlePerRowsChange} 
                    handlePageChange={handlePageChange} 
                    followSignal={followSignal} 
                    notFollowing={notFollowing}
                    stopFollowSignal={stopFollowSignal}
                    followSignalCustom={followSignalCustom}
                    />
                    <FollowerLogTable 
                    triggerHistory={historyOpen}
                    historicalList={historicalList}
                    handlePerRowsHistoricalChange={handlePerRowsHistoricalChange}
                    handlePageHistoricalChange={handlePageHistoricalChange}
                    totalHistoricalRows={totalHistoricalRows}
                    />
                </div>
            </div>
        </div>
    )
}

export default FollowerSidebar;