//Follower list table component rendered when signal provider user click on Follower button in Signal main view;
import DataTable from 'react-data-table-component' 
import '../../css/DataTable.scss'
import 'bootstrap/dist/css/bootstrap.min.css'
import { useTranslation } from "react-i18next";
import { useState } from "react";

function SignalFollowesTable(props) {

    //-----------------------------------------------------------
    //Declaration of constants, variables and hooks
    //-----------------------------------------------------------

    const { t } = useTranslation("global");
    const [filterValue, setFilterText] = useState('');
    const tabla_data = props.followerList;
    let filteredData = [];
    const filterValueLowercase = filterValue.toLowerCase();

    const handleFilterChange = (e) => {
      const value = e.target.value || '';
      setFilterText(value);
    };

    if(tabla_data){
      var updatedList = tabla_data.map(function(item) {
        
        if(item.copy_mode === "Proportional"){
          item.copy_mode = t("follower.Proportional");
        }
        else if(item.copy_mode === "FixedLots"){
          item.copy_mode = t("follower.Fixed Lots");
        }

        if(item.drawdown_mode === "NoDrawdown"){
          item.drawdown_mode = t("follower.No Drawdown");
        }
        else if(item.drawdown_mode === "InEquity"){
          item.drawdown_mode = t("follower.In Equity");
        }

        return item;
      });
    }

    if(updatedList){
        
      filteredData = updatedList.filter(
          (row) => {
          const columnsToValidate = [
              'slave_account',
              'copy_mode',
              'risk_ratio',
              'drawdown_mode',
              'max_drawdown',
            ];
        
            return columnsToValidate.some((column) => {
              const value = row[column];
        
              if (typeof value === 'number' && !isNaN(value)) {

                return value.toString().toLowerCase().includes(filterValueLowercase);
              }
        
              if (typeof value === 'string' && value.toLowerCase().includes(filterValueLowercase)) {
                return true; // Filtrar si es una cadena que incluye el valor de filtro
              }
        
              return false; // No filtrar en otros casos
            });
        });
        console.log(filteredData);
      }

    //The columnas constant contains an array of all the columns that the follower table will have and its cells settings and styles
    const columnas = [
        {
            name: <div className="title-format">{t("signalProvider.Followers")}</div>,
            selector: row => row['slave_account'],
            sortable: true,
            center: true,
            wrap: true,
            minWidth: '130px',
            fixed: "left",
            style: {
                color: 'black',
                backgroundColor: '#F5F5F5',
            },
        },
        {
            name: <div className="title-format">{t("follower.Copy Mode")}</div>,
            selector: row => row['copy_mode'],
            sortable: true,
            center: true,
            minWidth: '110px',
            wrap: true,
        },
        
        {
            name: <div className="title-format">{t("follower.Risk Radio")}</div>,
            selector: row => row['risk_ratio'],
            sortable: true,
            center: true,
            minWidth: '75px',
            wrap: true,
        },
        {
            name: <div className="title-format">{t("follower.DD Protection")}</div>,
            selector: row => row['drawdown_mode'],
            sortable: true,
            center: true,
            minWidth: '120px',
            wrap: true,
        },
        {
            name: <div className="title-format">{t("follower.Max DD")}</div>,
            selector: row => row['max_drawdown'],
            sortable: true,
            center: true,
            wrap: true,
            compact: true,
        },
        {
            name: <div className="title-format">{t("follower.Action")}</div>,
            selector: row => row['button'],
            sortable: true,
            button: true,
            cell: (row) => {
                return (
                      <button
                      type="button" 
                      className="button-follower" 
                      onClick={(e) => handleButtonClick(e, row.slave_account)}
                      >
                        {t("signalProvider.View Settings")}
                      </button>
                );
            },
            style: {
                backgroundColor: '#F5F5F5',
            }
        }      
    ];

    let menu = props.triggerFollower ? 'open' : 'closed';

    //Function handlers calls when user clicks on view settings button, this calls the props function TriggerFollowerSettings
    const handleButtonClick = (e, id) => {
        props.onOpenSetting(id);
    };
    
  return (
      <div className={`                     
                      ${'containerUsers-' + menu}
                  `}>
        <div className="table-responsive">
          <DataTable
            columns={columnas}
            data={filteredData}
            title={""}
            pagination
            paginationComponentOptions={{ rowsPerPageText: t("signalProvider.Rows per Page"), rangeSeparatorText: t("signalProvider.of"), selectAllRowsItem: true, selectAllRowsItemText: t("signalProvider.All"),}}
            paginationServer
            paginationTotalRows={props.totalHistoricalRows}
            onChangeRowsPerPage={props.handlePerRowsChange}
            onChangePage={props.handlePageChange}
            fixedHeader
            subHeader
            subHeaderComponent={
              <input
                type="text"
                placeholder={t("signalProvider.Search...")}
                value={filterValue}
                onChange={handleFilterChange}
              />
            }
          />
        </div>
      </div>
  );
}


export default SignalFollowesTable;
