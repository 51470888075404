//Signal provider Main component render when the user accepts disclouser adn the account type is a Signal provider
import Sidebar from "./Sidebar";
import "../../css/SignalProvider.scss";
import IdleTimerContainer from "../../config/iddleTimerContainer";
import { Navigate } from "react-router-dom";
import Cookies from "universal-cookie";
import { baseEnv } from "../../App";

function SignalProvider(props) {

    //-----------------------------------------------------------
    //Declaration of constants, variables and hooks
    //-----------------------------------------------------------

    const cookies = new Cookies();
    const user = cookies.get("user");
    const userType = cookies.get("account type"); //Gets from cookies is the user is Signal provider type

    //-----------------------------------------------------------
    //Functions and validations area
    //-----------------------------------------------------------

    //This validation protects the view in case someone access the route without do the login correctly
    if (user == null || userType !== "signal") {
        return <Navigate to={baseEnv + "/"} />
    }

    return (
        <div>
            <IdleTimerContainer></IdleTimerContainer>
            <Sidebar />
        </div>         
        );  
}

export default SignalProvider;