import classes from "../../css/LoginHead.module.scss";
import logo from "../../assets/Group 3.svg";

function LoginHead() {
  return (
      <div id="loginHead">
          <img
              className={classes.logo}
              src={logo}
              alt="Logo"
              htmlFor="logo-image"
          ></img>    
    </div>
  );
}

export default LoginHead;
