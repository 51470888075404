//Signal providers list table component rendered when follower user click on Signal provider button in follower main viewimport { useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css'
import DataTable from 'react-data-table-component'
import '../../css/DataTable.scss'
import { useTranslation } from "react-i18next";
import swal from "sweetalert2";
import { useState } from "react";

function FollowerSignalTable(props) {

    //-----------------------------------------------------------
    //Declaration of constants, variables and hooks
    //-----------------------------------------------------------

    const { t } = useTranslation("global");
    const [filterValue, setFilterText] = useState('');
    const tabla_data = props.signalList;
    let filteredData = [];
    const filterValueLowercase = filterValue.toLowerCase();

    const handleFilterChange = (e) => {
        const value = e.target.value || '';
        setFilterText(value);
    };

    if(tabla_data){
        var updatedList = tabla_data.map(function(item) {
          
          if(item.compensation === "NoCompensation"){
            item.compensation = t("follower.No Compensation");
          }
          else if(item.compensation === "DailyCopyFeeUSD"){
            item.compensation = t("follower.No Compensation");
          }
          else if(item.compensation === "PerformanceFee"){
            item.compensation = t("follower.No Compensation");
          }
  
          return item;
        });
      }
  
    if(updatedList){
        
        filteredData = updatedList.filter(
            (row) => {
            const columnsToValidate = [
                'strategy',
                'master_login',
                'total_change',
                'weekly_change',
                'daily_change',
                'compensation',
                'compensation_value',
            ];
        
            return columnsToValidate.some((column) => {
                const value = row[column];
        
                if (typeof value === 'number' && !isNaN(value)) {

                return value.toString().toLowerCase().includes(filterValueLowercase);
                }
        
                if (typeof value === 'string' && value.toLowerCase().includes(filterValueLowercase)) {
                return true; // Filter if the string has the filter value
                }
        
                return false; // Dont filter in other cases
            });
        });
        console.log(filteredData);
    }
    //The columnas constant contains an array of all the columns that the Signal providers table will have and its cells settings and styles
    const columnas = [
        {
            name: <div className="title-format">{t("follower.Signal Provider")}</div>,
            selector: row => row['strategy'] !== ""? row['strategy'] : row['master_login'] ,
            sortable: true,
            center: false,
            wrap: true,
            compact: false,
            minWidth: '100px',
            conditionalCellStyles: [    
                //group of validations to sett if this cell belongs to the signal provider followed        
                { when: row => row.master_login === props.notFollowing, style: { 
                    background: "#5FF0A8", 
                    borderTopStyle: 'solid', 
                    borderTopWidth: '2px', 
                    borderTopColor: 'gray',
                    borderTopShadow: '10px',
                    borderBottomStyle: 'solid', 
                    borderBottomWidth: '2px', 
                    borderBottomColor: 'gray',

                }}
            ]
            
        },
        {
            name: <div className="title-format">{t("follower.Total")} (%)</div>,
            selector: row => row['total_change'],
            sortable: true,
            center: true,
            compact: true,
            wrap: true,
            conditionalCellStyles: [
                //group of validations to sett if this cell belongs to the signal provider followed
                { when: row => row.total_change < 0, style: { color: '#FF4862', '&:hover': { cursor: 'pointer', }, }, },
                { when: row => row.total_change > 0, style: { color: '#09B87D', '&:hover': { cursor: 'pointer', }, }, },
                { when: row => row.master_login === props.notFollowing, style: { 
                    background: "#5FF0A8", 
                    borderTopStyle: 'solid', 
                    borderTopWidth: '2px', 
                    borderTopColor: 'gray',
                    borderTopShadow: '10px',
                    borderBottomStyle: 'solid', 
                    borderBottomWidth: '2px', 
                    borderBottomColor: 'gray',
                }},
            ],
        },
        {
            name: <div className="title-format">{t("follower.Weekly")} (%)</div>,
            selector: row => row['weekly_change'],
            sortable: true,
            center: true,
            compact: true,
            wrap: true,
            conditionalCellStyles: [
                //group of validations to sett if this cell belongs to the signal provider followed
                { when: row => row.weekly_change < 0, style: { color: '#FF4862', '&:hover': { cursor: 'pointer', }, }, },
                { when: row => row.weekly_change > 0, style: { color: '#09B87D', '&:hover': { cursor: 'pointer', }, }, },
                { when: row => row.master_login === props.notFollowing, style: { 
                    background: "#5FF0A8", 
                    borderTopStyle: 'solid', 
                    borderTopWidth: '2px', 
                    borderTopColor: 'gray',
                    borderTopShadow: '10px',
                    borderBottomStyle: 'solid', 
                    borderBottomWidth: '2px', 
                    borderBottomColor: 'gray',
                }},
            ],
        },
        {
            name: <div className="title-format">{t("follower.Daily")} (%)</div>,
            selector: row => row['daily_change'],
            sortable: true,
            center: true,
            compact: true,
            wrap: true,
            conditionalCellStyles: [
                //group of validations to sett if this cell belongs to the signal provider followed
                { when: row => row.daily_change < 0, style: { color: '#FF4862', '&:hover': { cursor: 'pointer', }, }, },
                { when: row => row.daily_change > 0, style: { color: '#09B87D', '&:hover': { cursor: 'pointer', }, }, },
                { when: row => row.master_login === props.notFollowing, style: { 
                    background: "#5FF0A8", 
                    borderTopStyle: 'solid', 
                    borderTopWidth: '2px', 
                    borderTopColor: 'gray',
                    borderTopShadow: '10px',
                    borderBottomStyle: 'solid', 
                    borderBottomWidth: '2px', 
                    borderBottomColor: 'gray',
                }},
            ],
        },
        {
            name: <div className="title-format">{t("signalProvider.Compensation")}</div>,
            selector: row => row['compensation'],
            sortable: true,
            center: true,
            wrap: true,
            compact: true,
            minWidth: '50px',
            conditionalCellStyles: [   
                //group of validations to sett if this cell belongs to the signal provider followed         
                { when: row => row.master_login === props.notFollowing, style: { 
                    background: "#5FF0A8", 
                    borderTopStyle: 'solid', 
                    borderTopWidth: '2px', 
                    borderTopColor: 'gray',
                    borderTopShadow: '10px',
                    borderBottomStyle: 'solid', 
                    borderBottomWidth: '2px', 
                    borderBottomColor: 'gray',
                }},
            ],
        },
        {
            name: <div className="title-format">{t("signalProvider.Compensation Value")}</div>,
            selector: row => row['compensation_value'],
            sortable: true,
            center: true,
            wrap: true,
            compact: true,
            minWidth: '50px',
            conditionalCellStyles: [    
                //group of validations to sett if this cell belongs to the signal provider followed        
                { when: row => row.master_login === props.notFollowing, style: { 
                    background: "#5FF0A8", 
                    borderTopStyle: 'solid', 
                    borderTopWidth: '2px', 
                    borderTopColor: 'gray',
                    borderTopShadow: '10px',
                    borderBottomStyle: 'solid', 
                    borderBottomWidth: '2px', 
                    borderBottomColor: 'gray',
                }}
            ]
        },
        {
            //Follow/Unfollow button
            name: <div className="title-format">{t("follower.Action")}</div>,
            selector: row => row['button'],
            sortable: true,
            button: true,
            cell: (row) => {
                return (
                      <button
                      //Validates if the row contains the data of the followed signal provider, if so, change the button to unfollow 
                      type="button" 
                      className={props.notFollowing === row.master_login || props.notFollowing === "" ? "button-follower"  : "button-Following"} 
                      onClick={(e) => handleButtonClick(e, row.master_login)}
                      disabled={props.notFollowing === row.master_login || props.notFollowing === "" ? false  : true}
                      >
                        {props.notFollowing === row.master_login ? "Unfollow" : "Follow"}
                      </button>
                );
            },
            style: {
                backgroundColor: '#F5F5F5',
            }
        }
    ];
    //Tabla_data gets the list of Signal providers avalaible to follow and the followed one, this is a prop sends by sidebarFollower

    //Validates if the history component was called by the user by click on history settings button, this adds an extra tag to the classname
    //to show or not the component
    let menu = props.triggerUser ? 'open' : 'closed';
    const followerSetting = {
        copy_mode: "Proportional",
        risk_ratio: "1",
        drawdown_mode: "NoDrawdown",
        max_drawdown: "0",
        min_lot_size: "0.01",
        max_lot_size: "0",
        connect: true,
    };

    //-----------------------------------------------------------
    //Functions and validations area
    //-----------------------------------------------------------

    //Function to handle when the follower button is press by the follower, it recieves an id from the master login of row select
    const handleButtonClick = (e, id) => { 
        //Validates if the id match with the Signal master login already followed by the follower, if so, the button trigger the unfollow Action 
        if(props.notFollowing === id){
            swal.fire({
                title: t("follower.Unfollow a Signal Provider"),
                text: t("follower.Are you sure to unfollow the signal provider ") + id + "?",
                icon: "warning",
                showCancelButton: true,
                cancelButtonText: t("follower.No"),
                cancelButtonColor: "#d33",
                confirmButtonText: t("follower.Yes"),
                confirmButtonColor: "#3085d6",
                iconColor: "orange",
                color: "#363636",
                background: "#FFFFFF",
            }).then((result) => {          
                if (result.isConfirmed) {
                    props.stopFollowSignal(id);             
                } else if (result.dismiss) {
                    swal.fire({
                        title: t("follower.Cancelled"),
                        icon: 'error',
                        confirmButtonColor: "#3085d6"
                    })
                }
            }); 
        }
        //If the id doesnt match with the followed signal login, or the follower doesnt follow any signal, the button triggers the follow action
        else{
            swal.fire({
                title: t("follower.Follow a Signal Provider"),
                text: t("follower.Are you sure to start following the signal provider ") + id + "?",
                icon: "warning",
                showDenyButton: true,
                denyButtonText: t("follower.Custom settings"),
                showCancelButton: true,
                cancelButtonText: t("follower.No"),
                cancelButtonColor: "#d33",
                confirmButtonText: t("follower.Default settings"),
                confirmButtonColor: "#3085d6",
                iconColor: "orange",
                color: "#363636",
                background: "#FFFFFF",
            }).then((result) => {    
                console.log(result);      
                if (result.isConfirmed) {
                    props.followSignal(id, followerSetting);             
                } else if (result.dismiss) {
                    swal.fire({
                        title: t("follower.Cancelled"),
                        icon: 'error',
                        confirmButtonColor: "#3085d6"
                    })
                }
                else if (result.isDenied) {
                    props.followSignalCustom(id);
                }
            });
        }     
    };

  return (
      <div className={`                     
                      ${'containerUsers-' + menu}
                  `}>
        <div className="table-responsive">
          <DataTable
            columns={columnas}
            data={filteredData}
            title={""}
            pagination
                  paginationComponentOptions={{ rowsPerPageText: t("signalProvider.Rows per Page"), rangeSeparatorText: t("signalProvider.of"), selectAllRowsItem: true, selectAllRowsItemText: t("signalProvider.All"), }}
            paginationServer
            paginationTotalRows={props.totalRows}
            onChangeRowsPerPage={props.handlePerRowsChange}
            onChangePage={props.handlePageChange}
            fixedHeader
            subHeader
            subHeaderComponent={
              <input
                type="text"
                placeholder={t("signalProvider.Search...")}
                value={filterValue}
                onChange={handleFilterChange}
              />
            }
          />
        </div>
      </div>
  );
}



export default FollowerSignalTable;
