//Signal settings log component rendered when Signal user click on Historical settings button in signa main view
import 'bootstrap/dist/css/bootstrap.min.css'
import DataTable from 'react-data-table-component'
import '../../css/DataTable.scss'
import { useTranslation } from "react-i18next";
import { useState } from "react";

function SignalLogsTable(props) {

    //-----------------------------------------------------------
    //Declaration of constants, variables and hooks
    //-----------------------------------------------------------

    const { t } = useTranslation("global");
    //Tabla_data gets the list of settigns did for the signal user, this is a prop sends by sidebar component
    const tabla_data = props.historicalList;
    const [filterValue, setFilterText] = useState('');
    let filteredData = [];
    const filterValueLowercase = filterValue.toLowerCase();

    const handleFilterChange = (e) => {
      const value = e.target.value || '';
      setFilterText(value);
    };

    if(tabla_data){
      var updatedList = tabla_data.map(function(item) {
        
        if(item.drawdown_mode === "NoDrawdown"){
          item.drawdown_mode = t("follower.No Drawdown");
        }
        else if(item.drawdown_mode === "InEquity"){
          item.drawdown_mode = t("follower.In Equity");
        }

        if(item.disable_account_on_drawdown === "True"){
          item.disable_account_on_drawdown = t("signalProvider.Yes");
        }
        else if(item.disable_account_on_drawdown === "False"){
          item.disable_account_on_drawdown = t("signalProvider.No");
        }

        if(item.allow_followers === "True"){
          item.allow_followers = t("signalProvider.Yes");
        }
        else if(item.allow_followers === "False"){
          item.allow_followers = t("signalProvider.No");
        }

        return item;
      });
    }

    if(updatedList){
        
      filteredData = updatedList.filter(
          (row) => {
          const columnsToValidate = [
              'time',
              'strategy',
              'drawdown_mode',
              'max_drowdown',
              'disable_account_on_drawdown',
              'allow_followers',
              'ip',
              'command',
            ];
        
            return columnsToValidate.some((column) => {
              const value = row[column];
        
              if (typeof value === 'number' && !isNaN(value)) {

                return value.toString().toLowerCase().includes(filterValueLowercase);
              }
        
              if (typeof value === 'string' && value.toLowerCase().includes(filterValueLowercase)) {
                return true; // Filtrar si es una cadena que incluye el valor de filtro
              }
        
              return false; // No filtrar en otros casos
            });
      });
      console.log(filteredData);
    }
    
    //The columnas constant contains an array of all the columns that the history table will have and its cells settings and styles
    const columnas = [
        {
            name: <div className="title-format">{t("follower.Time")}</div>,
            selector: row => row['time'],
            sortable: true,
            center: true,
            wrap: true,
            style: {
                color: 'black',
            },
        },
        /*{
            name: <div className="title-format">{t("signalProvider.Compensation")}</div>,
            selector: row => row['compensation'],
            sortable: true,
            center: true,
            wrap: true,
            compact: true,
        },
        {
            name: <div className="title-format">{t("signalProvider.Compensation Value")}</div>,
            selector: row => row['compensation_value'],
            sortable: true,
            center: true,
            wrap: true,
        },*/
        {
            name: <div className="title-format">{t("signalProvider.Strategy name")}</div>,
            selector: row => row['strategy'],
            sortable: true,
            center: true,
            wrap: true,
            compact: true,
        },
        {
            name: <div className="title-format">{t("signalProvider.Drawdown Protection")}</div>,
            selector: row => row['drawdown_mode'],
            sortable: true,
            center: true,
            wrap: true,
            compact: true,
        },
        {
            name: <div className="title-format">{t("signalProvider.Max Drawdown")}</div>,
            selector: row => row['max_drowdown'],
            sortable: true,
            center: true,
            wrap: true,
            compact: true,
        },
        {
            name: <div className="title-format">{t("signalProvider.Disable Account on Drawdown")}</div>,
            selector: row => row['disable_account_on_drawdown'],
            sortable: true,
            center: true,
            wrap: true,
            compact: true,
        },
        {
            name: <div className="title-format">{t("signalProvider.Visibility")}</div>,
            selector: row => row['allow_followers'],
            sortable: true,
            center: true,
            wrap: true,
            compact: true,
        },    
        {
            name: <div className="title-format">{t("follower.IP Address")}</div>,
            selector: row => row['ip'],
            sortable: true,
            center: true,
            wrap: true,
        },
        {
            name: <div className="title-format">{t("signalProvider.Message")}</div>,
            selector: row => row['command'],
            sortable: true,
            center: true,
            wrap: true,
        },

    ];

    //Validates if the history component was called by the user by click on history settings button, this adds an extra tag to the classname
    //to show or not the component
    let menu = props.triggerSignalHistory ? 'open' : 'closed';

  return (
      <div className={`                     
                    ${'containerHistory-' + menu}
                `}>
        <div className="table-responsive">
          <DataTable
            columns={columnas}
            data={filteredData}
            title={""}
            pagination
            paginationComponentOptions={{ rowsPerPageText: t("signalProvider.Rows per Page"), rangeSeparatorText: t("signalProvider.of"), selectAllRowsItem: true, selectAllRowsItemText: t("signalProvider.All"),}}
            paginationServer
            paginationTotalRows={props.totalHistoricalRows}
            onChangeRowsPerPage={props.handlePerRowsHistoricalChange}
            onChangePage={props.handlePageHistoricalChange}
            fixedHeader
            subHeader
            subHeaderComponent={
              <input
                type="text"
                placeholder={t("signalProvider.Search...")}
                value={filterValue}
                onChange={handleFilterChange}
              />
            }
          />
        </div>
      </div>
  );
}


export default SignalLogsTable;
