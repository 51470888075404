//Singleton script tha contains all the request uses in follower components
import axios from "axios";
import Cookies from "universal-cookie";

//Main path builds depending on the enviroment selected or run by administrator
const cookies = new Cookies();
const url = cookies.get("url");

let baseUrl = `${url}/api`;

function manejarCookieActualizada(event) {
    baseUrl = `${event.detail.value}/api`;
  }
  
// Agregar un listener para el evento de cookieActualizada
window.addEventListener('updatedUrl', manejarCookieActualizada);

//Request that bring the list of Signal providers available
const getMasters = async (quantity, page, headers) => {
    const res= await axios.get(`${baseUrl}/signal-list?quantity=${quantity}&page=${page}`, {headers});
    return res;       
};

//Request that starts to follow a Signal user
const follow = async (params, headers) => {
    const res= await axios.post(`${baseUrl}/follow`, params, {headers});
    return res;       
};

//Request that stops to follow a Signal user
const stopFollowing = async headers => {
    const res= await axios.delete(`${baseUrl}/follow`, {headers});
    return res;       
};

//It's not being used in this moment
const setGoodRate = async credentials => {
    const res= await axios.post(`${baseUrl}/login`, credentials);
    return res;       
};

//It's not being used in this moment
const setBadRate = async credentials => {
    const res= await axios.post(`${baseUrl}/login`, credentials);
    return res;       
};

//It's not being used in this moment
const connectMyAccount = async credentials => {
    const res= await axios.post(`${baseUrl}/login`, credentials);
    return res;       
};

//It's not being used in this moment
const disconnectMyAccount = async credentials => {
    const res= await axios.post(`${baseUrl}/login`, credentials);
    return res;       
};

//It's not being used in this moment
const disconnectMyAccountHidden = async credentials => {
    const res= await axios.post(`${baseUrl}/login`, credentials);
    return res;       
};

//Request that bring the list of historical settings made for follower user
const followerHistoricalSettings = async (quantity, page, headers) => {
    const res= await axios.get(`${baseUrl}/historical?quantity=${quantity}&page=${page}`, {headers});
    return res;       
};

//It's not being used in this moment
const followStatus = async params => {
    const res= await axios.post(`${baseUrl}/getSlaves`, params);
    return res;       
};

//It's not being used in this moment
const copyStatus = async params => {
    const res= await axios.post(`${baseUrl}/getSlaves`, params);
    return res;       
};

//Request that updates the information for a follower user
const editFollower = async (headers, params) => {
    const res= await axios.put(`${baseUrl}/information-follower`, params, {headers});
    return res;       
};

const methods = { 
    getMasters,
    follow, 
    stopFollowing, 
    setGoodRate, 
    setBadRate, 
    connectMyAccount, 
    disconnectMyAccount, 
    disconnectMyAccountHidden,
    followerHistoricalSettings,
    followStatus,
    copyStatus,
    editFollower
};

export default methods;