//Signal settings component render when the user click the setting button in sidebar component
import { useState, useEffect } from "react";
import "../../css/SignalProvider.scss";
import TitleIcon from "../../assets/g117.svg";
import Back from "../../assets/back.png";
import Power from "../../assets/power.svg";
import InfoIcon from "../../assets/info.png";
import { useTranslation } from "react-i18next";
import Switch from "react-switch";
import swal from "sweetalert2";
import Tooltip from '@mui/material/Tooltip';


function SettingForm(props) {  //This function gets props from sidebar component

    //-----------------------------------------------------------
    //Declaration of constants, variables and hooks
    //-----------------------------------------------------------

    const [t] = useTranslation("global");
    const [ddProt, setDdProt] = useState(""); 
    const [rnMode, setRnMode] = useState(false); 
    let ddProtection = "";
    //Validates if the settings component was called by the user by click on settings button, this adds an extra tag to the classname
    //to show or not the component
    let menu = props.triggerMenu ? 'open' : 'closed';

    //-----------------------------------------------------------
    //Functions and validations area
    //-----------------------------------------------------------

    //UseEffect hook triggered everytime the prop masterData, sends by sidebar, is changed. This hook upload the SignalSettings (ddProt)
    //state that contains all the follower settings information.
    useEffect(() => {
        setDdProt(props.masterData);
    }, [props.masterData]);

    useEffect(() => {
        setRnMode(props.masterStatus.read_only_was_triggered); // it's save whether or not the account has readOnlyFlag activated
    }, [props.masterStatus]);
    
    
    //Function handler calls when user changes disable account switch in signal settings form, this function uses the props request function updateSignalData to changes that value
    function handleDisableAccountOnDrawDown() {
        if (ddProt.disable_account_on_drawdown === false) {
            AlertMessageSelection(
                "signalProvider.Disable account on drawdown", 
                "signalProvider.Are you sure to deactivate the account when the maximum drawdown value is reached?")
            .then((result) => {
                if (result.isConfirmed) {
                    setDdProt({
                        ...ddProt,
                        disable_account_on_drawdown: true
                    });                   
                } else if (result.dismiss) {
                    swal.fire({
                        title: t("follower.Cancelled"),
                        icon: 'error',
                        confirmButtonColor: "#3085d6"
                    })
                }
            });
        }
        else {
            AlertMessageSelection(
                "signalProvider.Disable account on drawdown", 
                "signalProvider.Are you sure you do not disable your account when the maximum drawdown is reached?")
            .then((result) => {
                if (result.isConfirmed) {
                    setDdProt({
                        ...ddProt,
                        disable_account_on_drawdown: false
                    });     
                } else if (result.dismiss) {
                    swal.fire({
                        title: t("follower.Cancelled"),
                        icon: 'error',
                        confirmButtonColor: "#3085d6"
                    })
                }
            });
        }
        
    };

    //Function handler calls when signal user clicks on delete signal account on Signal Settings form, this function uses the delete props function to send the request 
    function deleteAccount() {
        if (props.checkedVisibility) {
            AlertMessageSelection(
                "signalProvider.Delete Signal provider account", 
                "signalProvider.Delete signal with visibility ON")
            .then((result) => {
                if (result.isConfirmed) {
                    props.deleteSignalProvider();                   
                } else if (result.dismiss) {
                    swal.fire({
                        title: t("follower.Cancelled"),
                        icon: 'error',
                        confirmButtonColor: "#3085d6"
                    })
                }
            });
        }
        else {
            AlertMessageSelection(
                "signalProvider.Delete Signal provider account", 
                "signalProvider.Are you sure to delete your signal provider account?")
            .then((result) => {
                if (result.isConfirmed) {
                    props.deleteSignalProvider(); 
                } else if (result.dismiss) {
                    swal.fire({
                        title: t("follower.Cancelled"),
                        icon: 'error',
                        confirmButtonColor: "#3085d6"
                    })
                }
            });
        }
    };

    //Function handler calls when user clicks on update Signal information on Signal Settings form, this function sends the changes to a props request function to update de Signal info
    function updateForm(message) {
        props.UpdateSignalData({
            disable_account_on_drawdown: ddProt.disable_account_on_drawdown,
            drawdown_mode: ddProt.drawdown_mode, 
            max_drowdown: ddProt.max_drowdown,
            strategy: ddProt.strategy
        }, "settings");  
    };  

    //Function that validates if the characters entered into the inputs is equals or lower than the maximum
    function enforceMinMax(el, type) {
        if (el.value !== "" && type === "num") {
          if (parseInt(el.value) > parseInt(el.max)) {
            switch (el.id){
                case "max_drawdown":
                    setDdProt({
                        ...ddProt,
                        max_drowdown: el.max
                    })
                    break;
                default:
                    break;  
            }
            el.value = el.max
          }
          if (parseInt(el.value) < parseInt(el.min)) {
            switch (el.id){
                case "max_drawdown":
                    setDdProt({
                        ...ddProt,
                        max_drowdown: el.min
                    })
                    break;
                default:
                    break;  
            }
            el.value = el.min
          }
        }
        else if(el.value !== "" && type === "text"){
            if (parseInt(el.value.length) > parseInt(el.max.length)) {               
                setDdProt({
                    ...ddProt,
                    strategy: el.value.slice(0, el.max.length)
                })           
                el.value = el.value.slice(0, el.max.length)
            }
            if (parseInt(el.value.length) < parseInt(el.min.length)) {               
                setDdProt({
                    ...ddProt,
                    strategy: el.value.slice(0, el.min.length)
                })           
                el.value = el.value.slice(0, el.min.length)
            }
                 
        }
    };
    
    //Validate whether or not to show max drawdown field if drawdown mode is In Equity or No drawdown         
    try{
        if (ddProt.drawdown_mode === "InEquity") {
        
            ddProtection = "show";
        }
        else if(ddProt.drawdown_mode === "NoDrawdown") {
            ddProtection = "noShow"
        };
    }catch(error){
    }

    //Function uses to show alert modals in many parts of the component
    async function AlertMessageSelection(title, message) {       
        const result = await swal.fire({
            title: t(title),
            text: t(message),
            icon: "warning",
            iconColor: "orange",
            color: "#363636",
            background: "#FFFFFF",
            showCancelButton: true,
            cancelButtonText: t("signalProvider.Cancel"),
            cancelButtonColor: "#3085d6",
            confirmButtonText: t("follower.Yes"),
            confirmButtonColor: "#FF0000"
        });
        return result;
    };
    
   
    return (
        <div className={`                     
                      ${'formContainer-' + menu}
                  `} id='settingContainer'>   
            <form className='settingForm' id="settingForm" >  
                <div className='titleContainer'>
                    <div className='titleSetting'>
                        <img
                            className='imageTitle'
                            src={TitleIcon}
                            alt="title"
                            htmlFor="title-icon"
                        ></img>
                        <title>{t("signalProvider.My settings")}</title>                       
                    </div> 
                    <button className='backIcon' type='button' onClick={props.onClose}>
                        <img
                            src={Back}
                            alt="back Icon"
                            htmlFor="back-icon" />
                    </button>
                </div> 
                <div> 
                    <div className='labelField'>
                        <label className='settingLabel'>{t("signalProvider.Strategy name")}</label>
                        <Tooltip title={t("signalProvider.Name of the strategy with which the followers will identify it")}>
                            <img
                                className='imageTitle'
                                src={InfoIcon}
                                alt="info"
                                htmlFor="info-icon"
                                data-tip data-for="infoTooltip"
                            ></img>
                        </Tooltip>
                    </div>
                    <input
                        className='input'
                        type="text"
                        id="strategy-name"
                        name="strategy-name"
                        defaultValue={ddProt.strategy}
                        value={ddProt.strategy != null ? ddProt.strategy : "No defined"}
                        min="0"
                        max="99999999999999999999999999999999999999"
                        onKeyUp={(e) => enforceMinMax(e.target, "text")}
                        onChange={(e) => setDdProt({
                            ...ddProt,
                            strategy: e.target.value
                        })}  
                        disabled={rnMode}   
                    ></input>
                </div>
                <div className='labelField'>
                    <label className='settingLabel'>{t("signalProvider.Drawdown Protection")}</label>
                    <Tooltip title={t("follower.Drawdown protection method")}>
                        <img
                            className='imageTitle'
                            src={InfoIcon}
                            alt="info"
                            htmlFor="info-icon"
                            data-tip data-for="infoTooltip"
                        ></img>
                    </Tooltip>
                </div>
                <select
                    className='input'
                    id="ddProtectionOption"
                    name="ddProtection"
                    value= {ddProt.drawdown_mode === null?  "NoDrawdown" : 
                            ddProt.drawdown_mode === "NoDrawdown" ? "NoDrawdown" : "InEquity"}
                    onChange={(e) => setDdProt({
                        ...ddProt,
                        drawdown_mode: e.target.value
                    })}  
                    disabled={rnMode}                
                >
                    <option id="noDrawdown" value="NoDrawdown">{t("signalProvider.No Drawdown")}</option>
                    <option id="inEquity" value="InEquity">{t("signalProvider.In Equity")}</option>
                </select>
                <div className={`dinamicInput ${ddProtection}`}> 
                    <div className='labelField'>
                        <label className='settingLabel'>{t("signalProvider.Max Drawdown")}</label>
                        <Tooltip title={t("follower.Maximum value for account Drawdown")}>
                            <img
                                className='imageTitle'
                                src={InfoIcon}
                                alt="info"
                                htmlFor="info-icon"
                                data-tip data-for="infoTooltip"
                            ></img>
                        </Tooltip>
                    </div>
                    <input
                        className='input'
                        type="number"
                        id="max_drawdown"
                        name="maxDdProtection"
                        value={ddProt.max_drowdown != null ? ddProt.max_drowdown : 0}
                        min="0"
                        max="9999999999"
                        onKeyUp={(e) => enforceMinMax(e.target, "num")}
                        onChange={(e) => setDdProt({
                            ...ddProt,
                            max_drowdown: e.target.value
                        })}  
                        disabled={rnMode}   
                    ></input>
                </div>
                <div className={`ddSwitch ${ddProtection}`}>
                    <label className='checkLabel'>{t("signalProvider.Disable Account on Drawdown")}</label>
                    <Switch
                        className="react-switch"
                        checked={ddProt.disable_account_on_drawdown != null ? ddProt.disable_account_on_drawdown : false}
                        onChange={(e) => handleDisableAccountOnDrawDown()}
                        offColor="#B71C1C"
                        onColor="#43A047"
                        onHandleColor="#FAFAFA"
                        offHandleColor="#FAFAFA"
                        handleDiameter={15}
                        uncheckedIcon={false}
                        checkedIcon={false}
                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                        height={15}
                        width={30}
                        id="material-switch"
                        disabled={rnMode}   
                    ></Switch>
                    <div className='labelField'>
                        <Tooltip title={t("signalProvider.When the switch is activated, the Signal provider account will not be able to open more trades if the maximum drawdown value is reached")}>
                            <img
                                className='imageTitle'
                                src={InfoIcon}
                                alt="info"
                                htmlFor="info-icon"
                                data-tip data-for="infoTooltip"
                            ></img>
                        </Tooltip>
                    </div>
                    
                </div>

                <button
                    className='loginBtn' 
                    type='button'
                    onClick={(e) => updateForm()}
                    disabled={rnMode}   
                >
                    {t("signalProvider.Update Settings")}
                </button>
                <button
                    className='loginBtn' 
                    id='deleteBtn'
                    type='button'
                    onClick={(e) => deleteAccount()}
                    disabled={rnMode}
                >
                    {t("signalProvider.Delete me as a Signal Provider")}
                </button>  
                <div>
                <label className='disableMessage' hidden={!rnMode}>{t("signalProvider.The account is disabled. Click on reactivate account to operate again")}</label>
                </div>
                <div className='linkReactivate'>
                    <img
                        className='reActivate'
                        src={Power}
                        alt="power"
                        htmlFor="reactivate-icon"
                        hidden={!rnMode}
                    ></img>
                    <button className='reActivate' type='button' onClick={(e) => props.reactivateSignalProvider()} hidden={!rnMode}> {t("signalProvider.Reactivate Account")} </button>
                </div>
            </form>            
        </div>
    );

}

export default SettingForm;