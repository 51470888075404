//Follower settings log component rendered when follower user click on Historical settings button in follower main view
import 'bootstrap/dist/css/bootstrap.min.css'
import DataTable from 'react-data-table-component'
import '../../css/DataTable.scss'
import { useTranslation } from "react-i18next";
import { useState } from "react";

function FollowerLogTable(props) {

    //-----------------------------------------------------------
    //Declaration of constants, variables and hooks
    //-----------------------------------------------------------

    const { t } = useTranslation("global");
    const [filterValue, setFilterText] = useState('');
    //Tabla_data gets the list of settigns did for the follower user, this is a prop sends by sidebarFollower
    const tabla_data = props.historicalList;  
    let filteredData = [];
    let filterValueLowercase = filterValue.toLowerCase();

    if(tabla_data){
      var updatedList = tabla_data.map(function(item) {
        
        if(item.drawdown_mode === "NoDrawdown"){
          item.drawdown_mode = t("follower.No Drawdown");
        }
        else if(item.drawdown_mode === "InEquity"){
          item.drawdown_mode = t("follower.In Equity");
        }

        if(item.copy_mode === "Proportional"){
          item.copy_mode = t("follower.Proportional");
        }
        else if(item.copy_mode === "FixedLots"){
          item.copy_mode = t("follower.Fixed Lots");
        }

        if(item.connect === true){
          item.connect = t("signalProvider.Yes");
        }
        else if(item.connect === false){
          item.connect = t("signalProvider.No");
        }

        return item;
      });
    }
    

    const handleFilterChange = (e) => {
        const value = e.target.value || '';
        setFilterText(value);
    };
  
    if(updatedList){
        
        filteredData = updatedList.filter(
            (row) => {
            const columnsToValidate = [
                'strategy',
                'master_account',
                'connect',
                'drawdown_mode',
                'max_drawdown',
                'max_lot_size',
                'min_lot_size',
                'modification_date',
                'risk_ratio',
                'copy_mode',
                'ip_address',
            ];
        
            return columnsToValidate.some((column) => {
                let value = row[column];
        
                if (typeof value === 'number' && !isNaN(value)) {

                return value.toString().toLowerCase().includes(filterValueLowercase);
                }

                if (typeof value === 'boolean' && !isNaN(value)) {

                    console.log(value);
                    return value.toString().toLowerCase().includes(filterValueLowercase);
                }

                if (typeof value === 'string' && value.toLowerCase().includes(filterValueLowercase)) {
                return true; // Filter if the string has the filter value
                }
        
                return false; // Dont filter in other cases
            });
        });
        console.log(filteredData)
    }
    //The columnas constant contains an array of all the columns that the history table will have and its cells settings and styles
    const columnas = [
        {
            name: <div className="title-format">{t("follower.Signal Provider")}</div>,
            selector: row => row['strategy'] !== "" && row['strategy'] !== "--" && row['strategy'] !== "0"? row['strategy'] : row['master_account'] !== "" && row['master_account'] !== "0"? row['master_account'] : "unfollowed",
            sortable: true,
            center: false,
            wrap: true,
            compact: false,
            minWidth: '100px',         
        },
        {
            name: <div className="title-format">{t("follower.Copy Mode")}</div>,
            selector: row => row['copy_mode'],
            sortable: true,
            center: true,
            wrap: true,
        },
        {
            name: <div className="title-format">{t("follower.Copy Status")}</div>,
            selector: row => row['connect'],
            sortable: true,
            center: true,
            wrap: true,
            compact: true,
        },
        {
            name: <div className="title-format">{t("follower.Risk Radio")}</div>,
            selector: row => row['risk_ratio'],
            sortable: true,
            center: true,
            wrap: true,
        },
        {
            name: <div className="title-format">{t("follower.DD Protection")}</div>,
            selector: row => row['drawdown_mode'],
            sortable: true,
            center: true,
            wrap: true,
        },
        {
            name: <div className="title-format">{t("follower.Max DD")}</div>,
            selector: row => row['max_drawdown'],
            sortable: true,
            center: true,
            wrap: true,
            compact: true,
        },
        {
            name: <div className="title-format">{t("follower.Min Lote Size")}</div>,
            selector: row => row['min_lot_size'],
            sortable: true,
            center: true,
            wrap: true,
        },
        {
            name: <div className="title-format">{t("follower.Max Lote Size")}</div>,
            selector: row => row['max_lot_size'],
            sortable: true,
            center: true,
            wrap: true,
        },
        {
            name: <div className="title-format">{t("follower.Time")}</div>,
            selector: row => row['modification_date'],
            sortable: true,
            center: false,
            compact: true,
            wrap: true,
        },
        {
            name: <div className="title-format">{t("follower.IP Address")}</div>,
            selector: row => row['ip_address'],
            sortable: true,
            center: true,
            compact: true,
            wrap: true,
        }
    ];

    //Validates if the history component was called by the user by click on history settings button, this adds an extra tag to the classname
    //to show or not the component
   let menu = props.triggerHistory ? 'open' : 'closed';

  return (
        <div className={`                     
                    ${'containerHistory-' + menu}
                `}>
        <div className="table-responsive">
          <DataTable
            columns={columnas}
            data={filteredData}
            title={""}
            pagination
            paginationComponentOptions={{rowsPerPageText: t("signalProvider.Rows per Page"),rangeSeparatorText: t("signalProvider.of"),selectAllRowsItem: true,selectAllRowsItemText: t("signalProvider.All"),}}
            paginationServer
            paginationTotalRows={props.totalHistoricalRows}
            onChangeRowsPerPage={props.handlePerRowsHistoricalChange}
            onChangePage={props.handlePageHistoricalChange}
            fixedHeader
            subHeader
            subHeaderComponent={
              <input
                type="text"
                placeholder={t("signalProvider.Search...")}
                value={filterValue}
                onChange={handleFilterChange}
              />
            }
          />
        </div>
      </div>
  );
}




export default FollowerLogTable;
