//Follower settings component render when the user click the setting button in sidebarfollower component
import { useState, useEffect } from "react";
import "../../css/SignalProvider.scss";
import InfoIcon from "../../assets/info.png";
import { useTranslation } from "react-i18next";
import Cookies from "universal-cookie";
import Tooltip from '@mui/material/Tooltip';


function FollowForm(props) { //This function gets props from sidebarfollower component

    //-----------------------------------------------------------
    //Declaration of constants, variables and hooks
    //-----------------------------------------------------------
    const [followModal, setFollowModal] = useState(props.openFollowModal);
    const [followerSettings, setFollowerSettings] = useState({
        copy_mode: "Proportional",
        risk_ratio: "1.0",
        drawdown_mode: "NoDrawdown",
        max_drawdown: "0",
        min_lot_size: "0.01",
        max_lot_size: "0",
        connect: true
    }); 
    const [t] = useTranslation("global");
    const cookies = new Cookies();
    const user = cookies.get("user");
    //Validates if the settings component was called by the user by click on settings button, this adds an extra tag to the classname
    //to show or not the component
    let menu = 'closed'; 
    let ddProtection = "";
    let copyLabel = "";
    let copyText = "";
    let showLoteAmount = "";

    useEffect(() => {
        setFollowModal(props.openFollowModal);
        console.log(followModal);
    }, [props.openFollowModal]);

    function updateForm() {
        props.followSignal( props.signalId, {
            //Fields to upload
            copy_mode: followerSettings.copy_mode,
            risk_ratio: followerSettings.risk_ratio,
            drawdown_mode: followerSettings.drawdown_mode,
            max_drawdown: followerSettings.max_drawdown,
            min_lot_size: followerSettings.min_lot_size,
            max_lot_size: followerSettings.max_lot_size,
            connect: followerSettings.connect,
            ip_address: props.ip
        });
    }; 

    if (followerSettings.drawdown_mode === "InEquity") {       
        ddProtection = "show";
    }
    else if(followerSettings.drawdown_mode === "NoDrawdown") {
        ddProtection = "noShow"
    }; 

    if (followerSettings.copy_mode === "Proportional") {
        copyLabel = t("follower.Risk Radio");
        copyText = t("follower.Proportion of open lots depending on the signal provider account");
        showLoteAmount = "show";
    }
    else if (followerSettings.copy_mode === "FixedLots") {
        copyLabel = t("follower.Fixed lot amount");
        copyText = t("follower.Fixed value of lots opened by the account in the case of copying a ticket from the signal provider account");
        showLoteAmount = "noShow";
    };

    
    if (followModal === false) {   
       menu = "open";
    }
    else if(followModal === true) {
        
        menu = "closed"
    }; 

    function enforceMinMax(el) {
        if (el.value !== "") {
          if (parseInt(el.value) > parseInt(el.max)) {
                switch (el.id){
                    case "risk-value":
                        followerSettings.risk_ratio = el.max;
                        break;
                    case "min-lote":
                        followerSettings.min_lot_size = el.max;
                        break;
                    case "max-lote":
                        followerSettings.max_lot_size = el.max;
                        break;
                    case "max-dd":
                        followerSettings.max_drawdown = el.max;
                        break;
                    default:
                        break;
                }
            el.value = el.max
          }
          if (parseInt(el.value) < parseInt(el.min)) {
            switch (el.id){
                case "risk-value":
                    followerSettings.risk_ratio = el.min;
                    break;
                case "min-lote":
                    followerSettings.min_lot_size = el.min;
                    break;
                case "max-lote":
                    followerSettings.max_lot_size = el.min;
                    break;
                case "max-dd":
                    followerSettings.max_drawdown = el.min;
                    break;
                default:
                    break;
            }
            el.value = el.min
          }
        }
    };

    const closeFollowModal = () => {
        setFollowModal(true);
        props.followModal(true);
    }
    
    return (
        <div className={`                     
                ${'followFormOverlay-' + menu}
            `}>
            <div className={`                     
                        ${'formFollow-' + menu}
                    `}>
                <form className='settingFormFollow' id="settingForm" >              
                    <div>
                    <title>{t("Following parameters")}</title>
                        <div className='labelField'>                        
                            <label className='settingLabel'>{t("login.Login")}</label>
                            <Tooltip title={t("follower.User identification in Metatrader 4")}>
                            <img
                                className='imageTitle'
                                src={InfoIcon}
                                alt="info"
                                htmlFor="info-icon"
                                data-tip data-for="infoTooltip"
                                ></img>
                            </Tooltip>
                        </div>                    
                        <input
                            className='input'
                            type="number"
                            id="user-login"
                            name="user-login"
                            disabled
                            defaultValue={user}
                        ></input>
                    </div>
                    <div className='labelField'>
                        <label className='settingLabel'>{t("follower.Copy Mode")}</label>
                        <Tooltip title={t("follower.Method of copying trades opened by the signal provider account")}>
                            <img
                                className='imageTitle'
                                src={InfoIcon}
                                alt="info"
                                htmlFor="info-icon"
                                data-tip data-for="infoTooltip"
                            ></img>
                        </Tooltip>
                    </div>               
                    <select
                        className='input'
                        id="copy-mode"
                        name="copy-mode"
                        onChange={(e) => setFollowerSettings({
                            ...followerSettings,
                            copy_mode: e.target.value
                        })}                 
                    >
                        <option id="proportional" value="Proportional">{t("follower.Proportional")}</option>
                        <option id="fixedLots" value="FixedLots">{t("follower.Fixed Lots")}</option>
                    </select>
                    <div>
                        <div className='labelField'>
                            <label className='settingLabel'>{copyLabel}</label>
                            <Tooltip title={ copyText }>
                                <img
                                    className='imageTitle'
                                    src={InfoIcon}
                                    alt="info"
                                    htmlFor="info-icon"
                                    data-tip data-for="infoTooltip"
                                ></img>
                            </Tooltip>
                        </div>                  
                        <input
                            className='input'
                            type="number"
                            id="risk-value"
                            defaultValue="1"
                            name="risk-value"
                            max="100"
                            min="0"  
                            onKeyUp={(e) => enforceMinMax(e.target)}
                            onChange={(e) => {followerSettings.risk_ratio = e.target.value}}                
                        ></input>
                    </div>
                    <div className={`dinamicInput ${showLoteAmount}`}>
                        <div className='labelField'>
                            <label className='settingLabel'>{t("follower.Min Lote Size")}</label>
                            <Tooltip title={t("follower.Minimum number of open lots when copying a trade")}>
                                <img
                                    className='imageTitle'
                                    src={InfoIcon}
                                    alt="info"
                                    htmlFor="info-icon"
                                    data-tip data-for="infoTooltip"
                                ></img>
                            </Tooltip>
                        </div>                   
                        <input
                            className='input'
                            type="number"
                            id="min-lote"
                            defaultValue="0.01"
                            name="min-lote"
                            max="99"
                            min="0"
                            onKeyUp={(e) => enforceMinMax(e.target)}
                            onChange={(e) => {followerSettings.min_lot_size = e.target.value}}
                        ></input>
                    </div>
                    <div className={`dinamicInput ${showLoteAmount}`}>
                        <div className='labelField'>
                            <label className='settingLabel'>{t("follower.Max Lote Size")}</label>
                            <Tooltip title={t("follower.Maximum number of open lots when copying a trade")}>
                                <img
                                    className='imageTitle'
                                    src={InfoIcon}
                                    alt="info"
                                    htmlFor="info-icon"
                                    data-tip data-for="infoTooltip"
                                ></img>
                            </Tooltip>
                        </div>               
                        <input
                            className='input'
                            type="number"
                            id="max-lote"
                            defaultValue="0"
                            name="max-lot"
                            max="100"
                            min="0"
                            onKeyUp={(e) => enforceMinMax(e.target)}
                        onChange={(e) => {followerSettings.max_lot_size = e.target.value}}
                        ></input>
                    </div>
                    <div className='labelField'>
                        <label className='settingLabel'>{t("signalProvider.Drawdown Protection")}</label>
                        <Tooltip title={t("follower.Drawdown protection method")}>
                            <img
                                className='imageTitle'
                                src={InfoIcon}
                                alt="info"
                                htmlFor="info-icon"
                                data-tip data-for="infoTooltip"
                            ></img>
                        </Tooltip>
                    </div>              
                    <select
                        className='input'
                        id="dd-protection"
                        name="dd-protection"
                        onChange={(e) => setFollowerSettings({
                            ...followerSettings,
                            drawdown_mode: e.target.value
                        })}
                    >
                        <option id="noDrawdown" value="NoDrawdown">{t("follower.No Drawdown")}</option>
                        <option id="inEquity" value="InEquity">{t("follower.In Equity")}</option>
                    </select> 
                    <div className={`dinamicInput ${ddProtection}`}>
                        <div className='labelField'>
                            <label className='settingLabel'>{t("signalProvider.Max Drawdown")}</label>
                            <Tooltip title={t("follower.Maximum value for account Drawdown")}>
                                <img
                                    className='imageTitle'
                                    src={InfoIcon}
                                    alt="info"
                                    htmlFor="info-icon"
                                    data-tip data-for="infoTooltip"
                                ></img>
                            </Tooltip>
                        </div>                
                        <input
                            className='input'
                            type="number"
                            id="max-dd"
                            name="max-dd"
                            defaultValue="0"
                            max="9999999999"
                            min="0"
                            onKeyUp={(e) => enforceMinMax(e.target)}
                            onChange={(e) => {followerSettings.max_drawdown = e.target.value}}
                        ></input>
                    </div> 
                    <div className="ActionPanel">
                        <button
                            className='agreeButton'
                            type="button"
                            id="agree-botom"
                            value="true"
                            onClick={(e) => updateForm()}
                        >
                            {t("follower.Confirm")}
                        </button>     
                        <button
                            className='loginBtnDisclouser'
                            type="button"
                            id="desagree-botom"
                            value="false"
                            onClick={closeFollowModal}
                        >
                            {t("signalProvider.Cancel")}
                        </button>  

                    </div>                   
                </form>
            </div>
        </div>
    );

}

export default FollowForm;