import classes from "../../css/Layout.module.scss";
import { useTranslation } from "react-i18next";
import Cookies from "universal-cookie";
import communitraders from "../../assets/communitradersManual.svg";

const Layout = (props) => {

    const { i18n } = useTranslation();
    const cookies = new Cookies();

    const changeLang = (e) =>{
        i18n.changeLanguage(e);
        cookies.set("lang", e, { path: "/" });
    }

    return (
        <div className={classes.content}>
            <div className={classes.languageSelector}>
                <select
                    className={classes.langInput}
                    id="language"
                    name="language"
                    onChange={(e) => changeLang(e.target.value)}
                >
                    <option value="en"> English</option>
                </select>
            </div>
            <div className={classes.manuals}>
                <a href="https://tradingplatformsupdatesbucket.s3.eu-west-2.amazonaws.com/CommuniTradersUserGuides/Communitraders+Web-++User+Manual+++(5).pdf" target="_blank">
                    <button className={classes.buttonManual}>
                        <img
                            className={classes.viewerIcon}
                            src={communitraders}
                            alt="reload Icon"
                            htmlFor="reload-icon" 
                        />
                    </button>
                </a>                
            </div>
            {props.children}
            
        </div>
    );
 
};

export default Layout;
