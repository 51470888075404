//Follower settings component render when the user click the setting button in sidebarfollower component
import { useState, useEffect } from "react";
import "../../css/SignalProvider.scss";
import TitleIcon from "../../assets/g117.svg";
import InfoIcon from "../../assets/info.png";
import Back from "../../assets/back.png";
import { useTranslation } from "react-i18next";
import Cookies from "universal-cookie";
import Tooltip from '@mui/material/Tooltip';


function SettingForm(props) { //This function gets props from sidebarfollower component

    //-----------------------------------------------------------
    //Declaration of constants, variables and hooks
    //-----------------------------------------------------------
 
    const [followerSettings, setFollowerSettings] = useState(""); 
    const [t] = useTranslation("global");
    const cookies = new Cookies();
    const user = cookies.get("user");
    //Validates if the settings component was called by the user by click on settings button, this adds an extra tag to the classname
    //to show or not the component
    let menu = props.triggerMenu ? 'open' : 'closed'; 
    let ddProtection = "";
    let copyLabel = "";
    let copyText = "";
    let showLoteAmount = "";
    var selectNoDrawdown = document.getElementById("noDrawdown");
    var selectInEquity = document.getElementById("inEquity");

    //-----------------------------------------------------------
    //Functions and validations area
    //-----------------------------------------------------------

    //UseEffect hook triggered everytime the prop followerData, sends by sidebarFollower, is changed. This hook upload the followerSettings
    //state that contains all the follower settings information.
    useEffect(() => {
        setFollowerSettings(props.followerData);
    }, [props.followerData]); //Trigger that runs again all what is inside effect hook

    //Function handler that does the call of the prop function updateFollowerSettings. This handler is trigger when follower user press
    //on update button.
    function updateForm(message) {
        props.UpdateFollowerSettings({
            //Fields to upload
            copy_mode: followerSettings.copy_mode,
            risk_ratio: followerSettings.risk_ratio,
            drawdown_mode: followerSettings.drawdown_mode,
            max_drawdown: followerSettings.max_drawdown === "" ? "0" : followerSettings.max_drawdown,
            min_lot_size: followerSettings.min_lot_size,
            max_lot_size: followerSettings.max_lot_size,
            connect: followerSettings.connect,
            ip_address: props.ip
        }, "follower.Follower information was successfully edited");  
    }; 

    //Function handler that limits the length of characters in every text and numeric input in the form
    function enforceMinMax(el) {
        if (el.value !== "") {
          if (parseInt(el.value) > parseInt(el.max)) {
                switch (el.id){
                    case "risk-value":
                        followerSettings.risk_ratio = el.max;
                        break;
                    case "min-lote":
                        followerSettings.min_lot_size = el.max;
                        break;
                    case "max-lote":
                        followerSettings.max_lot_size = el.max;
                        break;
                    case "max-dd":
                        followerSettings.max_drawdown = el.max;
                        break;
                    default:
                        break;
                }
            el.value = el.max
          }
          if (parseInt(el.value) < parseInt(el.min)) {
            switch (el.id){
                case "risk-value":
                    followerSettings.risk_ratio = el.min;
                    break;
                case "min-lote":
                    followerSettings.min_lot_size = el.min;
                    break;
                case "max-lote":
                    followerSettings.max_lot_size = el.min;
                    break;
                case "max-dd":
                    followerSettings.max_drawdown = el.min;
                    break;
                default:
                    break;
            }
            el.value = el.min
          }
        }
    };

    /*switch (followerSettings.drawdown_mode){      
        case "NoDrawdown" :
            selectNoDrawdown.setAttribute("selected", "true"); 
            selectInEquity.removeAttribute("selected"); 
            
            break;  
        case "InEquity" :
            selectInEquity.setAttribute("selected", "true"); 
            selectNoDrawdown.removeAttribute("selected");
            break; 
        default:
            break;
    }*/
    // Drawdown type validation runs every time the component is re render, this validates whether or not to show the fields drawdown mode,
    // disable on drawdown switch a max drawdown input
    try{ //This exception handlers avoid the app crashes when the follower doesnt have settings information
        if (followerSettings.drawdown_mode === "InEquity") {       
            ddProtection = "show";
        }
        else if(followerSettings.drawdown_mode === "NoDrawdown") {
            ddProtection = "noShow"
        };      
    }catch(error){
    }
    
    // Copy mode validation runs every time the component is re render, this validates whether or not to show the fields risk ratio,
    // max lot size and min lot size
    if (followerSettings.copy_mode === "Proportional") {
        copyLabel = t("follower.Risk Radio");
        copyText = t("follower.Proportion of open lots depending on the signal provider account");
        showLoteAmount = "show";
    }
    else if (followerSettings.copy_mode === "FixedLots") {
        copyLabel = t("follower.Fixed lot amount");
        copyText = t("follower.Fixed value of lots opened by the account in the case of copying a ticket from the signal provider account");
        showLoteAmount = "noShow";
    };
    
    return (
        <div className={`                     
                      ${'formContainer-' + menu}
                  `}>
            <form className='settingForm' id="settingForm" >
                <div className='titleContainer'>
                    <div className='titleSetting'>                        
                        <img
                            className='imageTitle'
                            src={TitleIcon}
                            alt="title"
                            htmlFor="title-icon"
                        ></img>
                        <title>{t("signalProvider.My settings")}</title>                        
                    </div>
                    <button className='backIcon' type='button' onClick={props.onClose}>
                        <img
                            src={Back}
                            alt="back Icon"
                            htmlFor="back-icon" />
                    </button>
                </div>                
                <div>
                    <div className='labelField'>                        
                        <label className='settingLabel'>{t("login.Login")}</label>
                        <Tooltip title={t("follower.User identification in Metatrader 4")}>
                        <img
                            className='imageTitle'
                            src={InfoIcon}
                            alt="info"
                            htmlFor="info-icon"
                            data-tip data-for="infoTooltip"
                            ></img>
                        </Tooltip>
                    </div>                    
                    <input
                        className='input'
                        type="number"
                        id="user-login"
                        name="user-login"
                        disabled
                        defaultValue={user}
                    ></input>
                </div>
                <div className='labelField'>
                    <label className='settingLabel'>{t("follower.Copy Mode")}</label>
                    <Tooltip title={t("follower.Method of copying trades opened by the signal provider account")}>
                        <img
                            className='imageTitle'
                            src={InfoIcon}
                            alt="info"
                            htmlFor="info-icon"
                            data-tip data-for="infoTooltip"
                        ></img>
                    </Tooltip>
                </div>               
                <select
                    className='input'
                    id="copy-mode"
                    name="copy-mode"
                    value= {followerSettings.copy_mode == null?  "Proportional" : 
                            followerSettings.copy_mode == "Proportional" ? "Proportional" : "FixedLots"}
                    onChange={(e) => setFollowerSettings({
                        ...followerSettings,
                        copy_mode: e.target.value
                    })} 
                >
                    <option id="proportional" value="Proportional">{t("follower.Proportional")}</option>
                    <option id="fixedLots" value="FixedLots">{t("follower.Fixed Lots")}</option>
                </select>
                <div>
                    <div className='labelField'>
                        <label className='settingLabel'>{copyLabel}</label>
                        <Tooltip title={ copyText }>
                            <img
                                className='imageTitle'
                                src={InfoIcon}
                                alt="info"
                                htmlFor="info-icon"
                                data-tip data-for="infoTooltip"
                            ></img>
                        </Tooltip>
                    </div>                  
                    <input
                        className='input'
                        type="number"
                        id="risk-value"
                        name="risk-value"
                        value={followerSettings.risk_ratio != null? followerSettings.risk_ratio : 0}
                        max="100"
                        min="0"
                        onKeyUp={(e) => enforceMinMax(e.target)}
                        onChange={(e) => setFollowerSettings({
                            ...followerSettings,
                            risk_ratio: e.target.value
                        })}                   
                    ></input>
                </div>
                <div className={`dinamicInput ${showLoteAmount}`}>
                    <div className='labelField'>
                        <label className='settingLabel'>{t("follower.Min Lote Size")}</label>
                        <Tooltip title={t("follower.Minimum number of open lots when copying a trade")}>
                            <img
                                className='imageTitle'
                                src={InfoIcon}
                                alt="info"
                                htmlFor="info-icon"
                                data-tip data-for="infoTooltip"
                            ></img>
                        </Tooltip>
                    </div>                   
                    <input
                        className='input'
                        type="number"
                        id="min-lote"
                        name="min-lote"
                        value={followerSettings.min_lot_size != null? followerSettings.min_lot_size : 0}
                        max="99"
                        min="0"
                        onKeyUp={(e) => enforceMinMax(e.target)}
                        onChange={(e) => setFollowerSettings({
                            ...followerSettings,
                            min_lot_size: e.target.value
                        })}   
                    ></input>
                </div>
                <div className={`dinamicInput ${showLoteAmount}`}>
                    <div className='labelField'>
                        <label className='settingLabel'>{t("follower.Max Lote Size")}</label>
                        <Tooltip title={t("follower.Maximum number of open lots when copying a trade")}>
                            <img
                                className='imageTitle'
                                src={InfoIcon}
                                alt="info"
                                htmlFor="info-icon"
                                data-tip data-for="infoTooltip"
                            ></img>
                        </Tooltip>
                    </div>               
                    <input
                        className='input'
                        type="number"
                        id="max-lote"
                        name="max-lot"
                        value={followerSettings.max_lot_size != null? followerSettings.max_lot_size : 0}
                        max="100"
                        min="0"
                        onKeyUp={(e) => enforceMinMax(e.target)}
                        onChange={(e) => setFollowerSettings({
                            ...followerSettings,
                            max_lot_size: e.target.value
                        })}  
                    ></input>
                </div>
                <div className='labelField'>
                    <label className='settingLabel'>{t("signalProvider.Drawdown Protection")}</label>
                    <Tooltip title={t("follower.Drawdown protection method")}>
                        <img
                            className='imageTitle'
                            src={InfoIcon}
                            alt="info"
                            htmlFor="info-icon"
                            data-tip data-for="infoTooltip"
                        ></img>
                    </Tooltip>
                </div>              
                <select
                    className='input'
                    id="dd-protection"
                    name="dd-protection"
                    value= {followerSettings.drawdown_mode == null?  "NoDrawdown" : 
                            followerSettings.drawdown_mode == "NoDrawdown" ? "NoDrawdown" : "InEquity"}
                    onChange={(e) => setFollowerSettings({
                        ...followerSettings,
                        drawdown_mode: e.target.value
                    })}
                >
                    <option id="noDrawdown" value="NoDrawdown">{t("follower.No Drawdown")}</option>
                    <option id="inEquity" value="InEquity">{t("follower.In Equity")}</option>
                </select> 
                <div className={`dinamicInput ${ddProtection}`}>
                    <div className='labelField'>
                        <label className='settingLabel'>{t("signalProvider.Max Drawdown")}</label>
                        <Tooltip title={t("follower.Maximum value for account Drawdown")}>
                            <img
                                className='imageTitle'
                                src={InfoIcon}
                                alt="info"
                                htmlFor="info-icon"
                                data-tip data-for="infoTooltip"
                            ></img>
                        </Tooltip>
                    </div>                
                    <input
                        className='input'
                        type="number"
                        id="max-dd"
                        name="max-dd"
                        defaultValue={props.followerData.max_drawdown}
                        value={followerSettings.max_drawdown != null? followerSettings.max_drawdown : 0}
                        max="9999999999"
                        min="0"
                        onKeyUp={(e) => enforceMinMax(e.target)}
                        onChange={(e) => setFollowerSettings({
                            ...followerSettings,
                            max_drawdown: e.target.value
                        })}  
                    ></input>
                </div>               
                <button
                    className={`
                        loginBtn
                        follower
                    `}
                    type='button'
                    onClick={(e) => updateForm()}
                >
                    {t("signalProvider.Update Settings")}
                </button>
            </form>
        </div>
    );

}

export default SettingForm;