import '../../css/SignalProvider.scss';

const Footer = (props) => {

    return (
        <div className="footer">
            2023 Tradeview® - Api Version: 5.3 - GUI Version: 1.4.02, <a href="https://www.tradeviewforex.com/" target="_blank">https://www.tradeviewforex.com/</a>
        </div>
    );
 
};

export default Footer;