//Singleton script tha contains all the request uses in Signal provider components
import axios from "axios";
import Cookies from "universal-cookie";


//Main path builds depending on the enviroment selected or run by administrator
const cookies = new Cookies();
const url = cookies.get("url");

let baseUrl = `${url}/api`;

function manejarCookieActualizada(event) {
    baseUrl = `${event.detail.value}/api`;
  }
  
// Agregar un listener para el evento de cookieActualizada
window.addEventListener('updatedUrl', manejarCookieActualizada);

//Request that bring the list followers than is following the signal provider
const getSlaves = async (quantity, page, headers) => {
        const res= await axios.get(`${baseUrl}/follower-list?quantity=${quantity}&page=${page}`, {headers});
        return res;       
};

//Request that delete a Signal provider account
const deleteMaster = async (headers) => {
    const res= await axios.delete(`${baseUrl}/become-signal`, {headers});
    return res;       
};

//Request that reactivate a disable Signal provider account
const reactivateAccount = async (headers) => {
    console.log(headers);
    const res= await axios.put(`${baseUrl}/reactivate`, 0,{headers});
    return res;       
};

//Request that update the information of a Signal provider account
const editMaster = async (headers, params) => {
    const res= await axios.put(`${baseUrl}/information-signal`, params, {headers});
    console.log(params);
    return res;       
};

//Request that bring the information about a follower that is following the Signal provider account
const getFollowerInformation = async (params, headers) => {
    console.log(params);
    const res= await axios.post(`${baseUrl}/information`, params, 
        {headers}
    );
    return res;       
};

//It's not being used in this moment
const makeVisible = async params => {
    const res= await axios.post(`${baseUrl}/discoverable`, params);
    return res;       
};

//It's not being used in this moment
const makeInvisible = async params => {
    const res= await axios.post(`${baseUrl}/undiscoverable`, params);
    return res;       
};

//Request that bring the list of historical settings made by signal provider user
const masterHistoricalSettings = async (quantity, page, headers) => {
    const res= await axios.get(`${baseUrl}/historical?quantity=${quantity}&page=${page}`, {headers});
    return res;       
};

const methods = { 
    getSlaves, 
    deleteMaster, 
    editMaster, 
    getFollowerInformation,
    makeVisible, 
    makeInvisible, 
    reactivateAccount,
    masterHistoricalSettings,
};

export default methods;