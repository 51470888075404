import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import classes from "../../css/LoginForm.module.scss";
import logo from "../../assets/Group 3.svg";
import signalProviderIcon from "../../assets/Group 8.svg";
import { useTranslation } from "react-i18next";
import swal from "sweetalert2";
import loginService from "../../services/login";
import userService from "../../services/user";
import LoadingLogin from "../gadgets/loadingLogin"
import Show from "../../assets/show.png";
import Hide from "../../assets/hide.png";
import { urls } from "../../config/urls";
import { baseEnv, server } from "../../App";

function LoginForm(props) {

    //-----------------------------------------------------------------------
    // Hooks, const and variables declaration
    //-----------------------------------------------------------------------

    const [t] = useTranslation("global");
    const [usu, setUsu] = useState("");
    const [pass, setPass] = useState("");
    const [accTyp, setAccTyp] = useState("");
    const [loading, setLoading] = useState(false);
    const [showUrl, setShowUrl] = useState(false);
    const [plat, setPlat] = useState("");
    const [showServer, setShowServer] = useState(true);
    const [show, setShow] = useState(false);
    const [ser, setSer] = useState("");
    const [ip, setIp] = useState("");
    const navigate = useNavigate();
    const env = process.env.REACT_APP_URL;
    let url = "";
    
    
    const cookies = new Cookies();
    let title = "";

    //-----------------------------------------------------------------------
    // Credentials and token cookies deleted
    //-----------------------------------------------------------------------

    cookies.remove("user", { path: '/' });
    cookies.remove("account type", { path: '/' });
    cookies.remove("token", { path: '/' });

    //-----------------------------------------------------------------------
    // Checking out MT version for showing in views and login parameter
    //-----------------------------------------------------------------------  

    /*const envCutted = env.substring(0, 26);
    if (envCutted === "https://communitraders-mt4") {
        title = "login.Enter your Metatrader 4 credentials";
    }
    else if (envCutted === "https://communitraders-mt5") {
        title = "login.Enter your Metatrader 5 credentials";
    }
    else if (env === "http://3.8.90.169:5001") {
        title = "login.Enter your Metatrader 4 credentials";
    }*/

    //-----------------------------------------------------------------------
    // Credential validation handler
    //-----------------------------------------------------------------------
    const getIp = () => {
        setLoading(true);  //Starts loading spinning
        userService.getIpClient( //Calls the method getIpClient from user service (axios request)
        )
        .then(response=>{ //Waits for a response from the axios request
            setLoading(false);  //Stops loading spinning        
            return response;
        })
        .then(response=>{
            if(response !== null){ 
                setIp(response.data.ip); //If response is correct, sets ip into the hook ip.
            }
            else{
                console.log("not ip")
                getIp();
            }           
        })
        .catch(error => {
            setLoading(false); //Stops loading spinning  
            console.log(error);
            getIp();
        });
    };


    const handleLogin = async (e) => {      
        e.preventDefault();

        // Validates if login form was completly fill out
        if (usu.length === 0 || pass.length === 0 || accTyp.length === 0) {
            swal.fire({
                text: t("login.Please, fill in the missing data"),
                icon: "warning",
                iconColor: "red",
                color: "#363636",
                background: "#FFFFFF",
                confirmButtonColor: "#FF0000"
            });
        }
        else{   
            //Calls login service to do login into the communitraders api 
            setLoading(true);                      
            loginService.login({
                //Login request body
                platform: env,
                environment: server,
                role: accTyp,
                account: usu,
                password: pass,
                ip: ip,
            })
            .then(response=>{
                return response.data;
            })
            .then(response=>{
                //Checking out if the response return the login data
                if(response.data != null){ 

                    var user = response.data;  
                    cookies.set("token", user.token, { path: "/" });
                    
                    //If the response was correct, the credentials hooks are deleted                           
                    setUsu("");
                    setPass("");
                    let loginMessage = "" ;

                    // Checks out if the account type is signal or follower
                    if(accTyp === "signal"){
                        //If the account type is signal, set the cookies with the access data (user, account type and token)
                        cookies.set("user", usu, { path: "/" });
                        cookies.set("account type", accTyp, { path: "/" });
                        //If the account type is signal the disclouser screen will be displayed                          
                        loginMessage = "login.Welcome signal provider user"                
                    }
                    else if(accTyp === "follower") {   
                        //If the account type is follower, set the cookies with the access data (user, account type and token)                                   
                        cookies.set("user", usu, { path: "/" });
                        cookies.set("account type", accTyp, { path: "/" });                       
                        loginMessage = "login.Welcome follower user";                    
                    }
                    //Show an alert message and redirect to disclouser screen
                    swal.fire({
                        text: t(loginMessage),
                        icon: "success",
                        iconColor: "green",
                        color: "#363636",
                        background: "#FFFFFF",
                        confirmButtonColor: "#FF0000"
                    });
                    navigate(baseEnv + "/Disclouser");
                //if the response is null a warning will be displayed
                }else{
                    swal.fire({
                        text: t("login.Invalid access credentials"),
                        icon: "error",
                        iconColor: "red",
                        color: "#363636",
                        background: "#FFFFFF",
                        confirmButtonColor: "#FF0000"
                    }); 
                }
            })
            .catch(error => {
                console.log(error);
                swal.fire({
                    text: t("login.Bad request, please check your account information"),
                    icon: "error",
                    iconColor: "red",
                    color: "#363636",
                    background: "#FFFFFF",
                    confirmButtonColor: "#FF0000"
                }); 
                setLoading(false);
            })                                          
        }                                            
    }

    //-----------------------------------------------------------------------
    // Create a new signal provider (Become a signal provider)
    //-----------------------------------------------------------------------

    const handleBecomeSignal = async (e) => {      
        e.preventDefault();

        // Validates if login form was completly fill out
        if (usu.length === 0 || pass.length === 0) {
            swal.fire({
                text: t("login.Please, fill in the missing data"),
                icon: "warning",
                iconColor: "red",
                color: "#363636",
                background: "#FFFFFF",
                confirmButtonColor: "#FF0000"
            });
        }
        else{   
            //Calls login service to do login into the communitraders api 
            setLoading(true);                     
            loginService.createMaster({
                //Login request body
                platform: env,
                environment: server,
                account: usu,
                password: pass,
                ip: ip,
            })          
            .then(response=>{
                return response.data;
            })
            .then(response=>{
                //Checking out if the response return the login data
                if(response.data != null){ 
                    //If the response was correct, the credentials hooks are deleted                           
                    //Show an alert message and redirect to disclouser screen
                    swal.fire({
                        text: t("login.Signal Provider created. Please, use the credential to log in"),
                        icon: "success",
                        iconColor: "green",
                        color: "#363636",
                        background: "#FFFFFF",
                        confirmButtonColor: "#FF0000"
                    });
                    navigate(baseEnv + "/Disclouser");
                //if the response is null a warning will be displayed
                }else{
                    swal.fire({
                        text: t("login.Invalid access credentials"),
                        icon: "error",
                        iconColor: "red",
                        color: "#363636",
                        background: "#FFFFFF",
                        confirmButtonColor: "#FF0000"
                    }); 
                }
            })
            .catch(error => {
                console.log(error);
                if(error.response.data.message === "This account is already a Signal Provider"){
                    swal.fire({
                        text: t("This account is already a Signal Provider"),
                        icon: "error",
                        iconColor: "red",
                        color: "#363636",
                        background: "#FFFFFF",
                        confirmButtonColor: "#FF0000"
                    }); 
                } 
                else{
                    swal.fire({
                        text: t("login.Internal Server Error"),
                        icon: "error",
                        iconColor: "red",
                        color: "#363636",
                        background: "#FFFFFF",
                        confirmButtonColor: "#FF0000"
                    }); 
                }       
                setLoading(false);
            })                                          
        }                                            
    }

    // Function in charge of limit the number of characters in an input

    function enforceMinMax(el) {
        if (el.value !== "") {
            if (parseInt(el.value.length) > parseInt(el.max.length)) {  
            el.value = el.value.slice(0, el.max.length)
          }
          if (parseInt(el.value) < parseInt(el.min)) {
            el.value = el.min
          }
        }
      };

    // Function to handler when a user click on show password button in login form, allows to see the password written by user
    const showPassword = (e) => {
        setShowUrl(!showUrl);
        var tipo = document.getElementById("user-password");
        if(tipo.type === "password"){
            tipo.type = "text";
        }else{
            tipo.type = "password";
        }
    }

    const choosePlatform = (e) => {
        setPlat(e);
        if(e === "mt4" || e === "mt5"){
            setShowServer(false);
        }
        else{
            setShowServer(true);
        }
        if(e === "mt4"){
            setShow(false);
            cookies.set("platform", e, { path: "/" });
        }
        else if(e === "mt5"){
            setShow(true);
            cookies.set("platform", e, { path: "/" });
        }
    }

    //USe Effect hook triggered once per render, it calls a function to request the user ip
    useEffect(() => {
        getIp();
    }, []);

    //USe Effect hook triggered once per render, it calls a function to request the user ip
    useEffect(() => {
        cookies.remove("url", { path: '/' });
        switch (plat){
            case "mt4":
                if(ser === "live-1"){
                    url = urls.mt4Live1;
                    cookies.set("url", urls.mt4Live1, { path: "/" });
                }
                else if(ser === "live-2"){
                    url = urls.mt4Live2;
                    cookies.set("url", urls.mt4Live2, { path: "/" });
                }
                else if(ser === "demo"){
                    url = urls.mt4Demo;
                    cookies.set("url", urls.mt4Demo, { path: "/" });
                }
                else if(ser === "test"){
                    url = urls.mt4Test;
                    cookies.set("url", urls.mt4Test, { path: "/" });
                }          
            break;

            case "mt5":
                if(ser === "live"){
                    url = urls.mt5Live;
                    cookies.set("url", urls.mt5Live, { path: "/" });
                }
                else if(ser === "demo"){
                    url = urls.mt5Demo;
                    cookies.set("url", urls.mt5Demo, { path: "/" });
                }
                else if(ser === "test"){
                    url = urls.mt5Test;
                    cookies.set("url", urls.mt5Test, { path: "/" });
                }
            break;

            default:
            break;
        }
        const urlUpdatedEvent = new CustomEvent('updatedUrl', {
            detail: { value: url }
          });

          window.dispatchEvent(urlUpdatedEvent);
    }, [plat, ser]);


    //Render of html login components
    return (

        <div className={classes.formContainer}>
            <LoadingLogin loading={loading}/>
            <div className={classes.logoContainer}>
                <img
                    className={classes.logo}
                    src={logo}
                    alt="Logo"
                    htmlFor="logo-image"
                ></img>
            </div>
            <form className={classes.form} id="form" >
                <title className={classes.loginTitle}>{t("login.Enter your Metatrader credentials")}</title>
                <div>                   
                    <label className='settingLabel'>{t("signalProvider.Login")}</label>
                    <input
                        className={classes.input}
                        type="number"
                        id="user-name"
                        name="user-name" 
                        min="0"
                        max="999999999999999"
                        onKeyUp={(e) => enforceMinMax(e.target)}
                        onChange={(e) => setUsu(e.target.value)}
                        required
                        disabled={loading}
                    ></input>
                </div>

                <div className={classes.input_wrapper}>
                    <label className='settingLabel'>{t("login.Password")}</label>
                    <input
                        className={classes.input}
                        type="password"
                        id="user-password"
                        name="user-password"
                        autoComplete="off"
                        max="99999999999999999999"
                        onKeyUp={(e) => enforceMinMax(e.target)}
                        onChange={(e) => setPass(e.target.value)}
                        required
                        disabled={loading}
                    ></input>
                    <button className={classes.showPassword} type='button' onClick={(e) => showPassword()}>
                            <img
                             src={showUrl === false ? Show : Hide}
                             alt="show Icon"
                            htmlFor="show-icon" />
                    </button>
                </div>
                <div>
                    <label className='settingLabel'>{t("login.Account Type")}</label>
                    <select
                        className={classes.input}
                        id="account-type"
                        name="account-type"
                        onChange={(e) => setAccTyp(e.target.value)}
                        required
                        disabled={loading}
                    >
                        <option value="">{t("login.Select an account type")}</option>
                        <option value="follower">{t("login.Follower")}</option>
                        <option value="signal">{t("login.Signal Provider")}</option>
                    </select>
                </div>
                <div>
                    <label className='settingLabel'>{t("login.Platform")}</label>
                    <select
                        className={classes.input}
                        id="platform"
                        name="platform"
                        onChange={(e) => choosePlatform(e.target.value)}
                        required
                        disabled={loading}
                    >
                        <option value="">{t("login.Select a platform")}</option>
                        <option value="mt4">Metatrader 4</option>
                        <option value="mt5">Metatrader 5</option>
                    </select>
                </div>
                <div>
                    <label className='settingLabel' hidden={showServer}>{t("login.Server")}</label>
                    <select
                        className={classes.input}
                        id="server"
                        name="server"
                        onChange={(e) => setSer(e.target.value)}
                        required
                        hidden={showServer}
                        disabled={loading}
                    >
                        <option value="">{t("login.Select a server")}</option>
                        <option value="demo">Demo</option>
                        <option id="live" value="live" hidden={!show}>Live</option>
                        <option value="live-1" hidden={show}>Live 1</option>
                        <option value="live-2" hidden={show}>Live 2</option>
                        {/* <option value="test">Test</option> */}
                    </select>
                </div>

                <button
                    className={classes.loginBtn}
                    onClick={handleLogin}
                    disabled={loading}
                >
                    {t("login.Login")}
                </button>
                <div className={classes.LinkBox}>
                    <img
                        className={classes.icon}
                        src={signalProviderIcon}
                        alt="Signal provider icon"
                        htmlFor="signal-provider"                       
                    ></img>
                    <button className={classes.Link} type='button' onClick={handleBecomeSignal} disabled={loading}> {t("login.Become a signal provider")} </button>
                </div>
            </form>
        </div>
    );

}

export default LoginForm;
