const urls = {
    mt4Test: 'http://3.8.90.169:5001',
    mt4Demo: 'https://communitraders-mt4-demo.tradeviewforex.com',
    mt4Live1: 'https://communitraders-mt4-live-1.tradeviewforex.com',
    mt4Live2: 'https://communitraders-mt4-live-2.tradeviewforex.com',
    mt5Test: 'http://3.8.90.169:5005',
    mt5Demo: 'https://communitraders-mt5-demo.tradeviewforex.com',
    mt5Live: 'https://communitraders-mt5-live.tradeviewforex.com',
  };
  
  export { urls };