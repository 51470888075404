import Layout from "./components/Login/Layout";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import LoginForm from "./components/Login/LoginForm";
import LoginHead from "./components/Login/LoginHead";
import SignalProvider from "./components/SignalProvider/SignalProvider";
import Disclouser from "./components/Disclouser/Disclouser";
import Follower from "./components/Follower/Follower";
import Swagger from "./components/404/Swagger";
import NotFound from "./components/404/notFound";
import { useState } from "react";
import { t } from "i18next";


export const baseEnv = ``;/*`/${process.env.REACT_APP_ENV}/${process.env.REACT_APP_SERVER}`;*/
export const env = ``; /*process.env.REACT_APP_ENV;*/
export const server = ``; /*process.env.REACT_APP_SERVER;*/


function App(props) {

  return (
      <BrowserRouter>
          <Routes>
              <Route path={baseEnv + "/"} element={
                  <Layout>
                      <LoginForm/>
                  </Layout>
              } />
              <Route exact path={baseEnv + "/SignalProvider"} element={                 
                      <SignalProvider />                           
              } />
              <Route exact path={baseEnv + "/Disclouser"} element={
                  <Layout>
                      <LoginHead />
                      <Disclouser/>
                  </Layout>
              } />
              <Route exact path={baseEnv + "/Follower"} element={
                  <Follower/>
              } />
              <Route exact path={baseEnv + "/Swagger"} element={
                  <Swagger/>
              } />
              <Route exact path="*" element={
                <NotFound/>
              } />
          </Routes>
      </BrowserRouter>    
  );
}

export default App;
