//Signal provider sidebar componen render when user log in with signal provider credentials, this component managments all the requests 
//and subcomponents about signal provider profile
import React, { useEffect, useState } from "react";
import '../../css/SignalProvider.scss';
import logo from "../../assets/Group 4.svg";
import PersonIcon from "../../assets/Group 29.svg";
import reload from "../../assets/re load icon.svg";
import { useInterval } from "../../config/useInterval";
import InfoIcon from "../../assets/info.png";
import Switch from "react-switch";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import SettingForm from "./SignalSettings";
import SignalFollowesTable from "./Signal_Followes_Table";
import SignalLogsTable from "./Signal_Logs_Table";
import FollowerSettingForm from "./followerSignalSettings";
import swal from "sweetalert2";
import Tooltip from '@mui/material/Tooltip';
import signalService from "../../services/signalProvider";
import userService from "../../services/user";
import Loading from "../gadgets/loading";
import Cookies from "universal-cookie";
import { baseEnv } from "../../App";


function Sidebar() {

    //-----------------------------------------------------------
    //Declaration of constants, variables and hooks
    //-----------------------------------------------------------

    const [leftOpen, setLeftOpen] = useState(true);  
    const [checked, setChecked] = useState(false);
    const navigate = useNavigate();
    const [t] = useTranslation("global");
    const [trig, setTrig] = useState(false); 
    const [followTrig, setFollowTrig] = useState(false); 
    const [followerOpen, setFollowerOpen] = useState(true);
    const [historyOpen, setHistoryOpen] = useState(false);
    const [idFollower, setIdFollower] = useState("");
    const [loading, setLoading] = useState(false);
    const [errorMes, setErrorMes] = useState(null);
    const [masterStatus, setMasterStatus] = useState({}); 
    const [masterData, setMasterData] = useState({}); 
    const [followerData, setFollowerData] = useState(""); 
    const [followerList, setFollowerList] = useState("");
    const [historicalList, setHistoricalList] = useState("");
    const [totalRows, setTotalRows] = useState(0);
    const [quantityPerPage, setQuantityPerPage] = useState(10);
    const [page, setPage] = useState(1);
    const [totalHistoricalRows, setTotalHistoricalRows] = useState(0);
    const [quantityHistoricalPerPage, setQuantityHistoricalPerPage] = useState(10);
    const [pageHistorical, setHistoricalPage] = useState(1);
    const [periodicFlag, setPeriodicFlag] = useState(false);
    const [nonPeriodicFlag, setNonPeriodicFlag] = useState(false);
    const cookies = new Cookies();
    const user = cookies.get("user");
    const token = cookies.get("token"); 
    let headers = { //Headers preparation, the token is get from cookies, the same token obtained when the user log in
        "Content-type": "application/json; charset=UTF-8",
        "Authorization": "Bearer " + token
    };
    let leftOpen2 = leftOpen ? 'open' : 'closed';
    let followerOn = followerOpen ? 'ON' : 'OFF';
    let historyOn = !followerOpen ? 'ON' : 'OFF';
    let title = "";

    const platform = cookies.get("platform");;
    if (platform === "mt4") {
        title = "signalProvider.SIGNAL PROVIDER TERMINAL MT4";
    }
    else if (platform === "mt5") {
        title = "signalProvider.SIGNAL PROVIDER TERMINAL MT5";
    }

    //-----------------------------------------------------------
    //Requests area
    //-----------------------------------------------------------

    /*const getIp = () => {
        userService.getIpClient(
        )
        .then(response=>{ 
            setLoading(false);          
            return response;
        })
        .then(response=>{
            if(response != null){ 
                console.log(response.ip);
                setIp(response.ip);
            }
            else{
                console.log("not ip")
            }           
        }).
        catch(error => {
            setErrorMes(true);
            setLoading(false); 
            console.log(error)
        });
    };*/

    function wait(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    //main function to call sequentially every get Endpoint in Signal Provider components
    const LoadSignalStatus = async (periodically) => {
        if(periodically === false){
            setLoading(true);
            if(periodicFlag === false){
                setNonPeriodicFlag(true);
            }
            else{
                await wait(1000);
                setPeriodicFlag(false);
            }           
        }
        else{
            setLoading(loading);
        }
        //Validates if there is a request working in this moment and if so, the periodical request is skip
        if(periodically === true && nonPeriodicFlag === true){
            return;
        }
        else{
            setPeriodicFlag(true);
        }
        //periodically === false ? setLoading(true) : setLoading(loading);//Starts loading spinning, validates if the petition is periodic
        userService.getStatus( //Calls the method getStatus from user service (axios request), its passed the headers
            headers
        )
        .then(response=>{ //Waits for a response from the axios request  
            return response.data;
        })
        .then(response=>{
            LoadSignalData(periodically, true); //Calls de function loadSignalData once finished the first request, periodically is a flag to define if the request was done by manually or automatic from time to time
            if(response.data != null){ 
                setMasterStatus(response.data); //If response is correct, sets ip into the hook ip.  
                console.log("Status Ok");            
            }
            else{
                console.log("not data");   
                if(!periodically) AlertMessage("signalProvider.Server error, try again", "error", "red", "");            
            } 
                    
        })
        .catch(error => {
            if(error.response.data.message === "Login is required"){ //Validates if the axios error was due to a login is required
                AlertMessage("follower.Session finished, please log in again", "error", "red", ""); //If validation is fulfilled, A notice is displayed and redirected to login
                setLoading(false); //Stops loading spinning 
                setNonPeriodicFlag(false);
                setPeriodicFlag(false);
                console.log(error);
                navigate(baseEnv + "/"); //Method to redirect to the login component
            }
            else{
                if(!periodically) AlertMessage("signalProvider.Server error, try again", "error", "red", ""); //Default error message, validates if it's a periodical request        
                setLoading(false); 
                setNonPeriodicFlag(false);
                setPeriodicFlag(false);
                console.log(error);
            }
        });
                               
    }

    //Function in charge of making the configuration request of the signal provider to the communitraders api
    const LoadSignalData = async (periodically, chained) => {
        if(!periodically){
            setLoading(true);
            if(periodicFlag === false){
                setNonPeriodicFlag(true);
            }
            else if(chained === false){
                await wait(1000);
                setNonPeriodicFlag(true);
            }
            userService.getInformation(
                headers
                //Login request body
            )
            .then(response=>{
                return response.data;
            })
            .then(response=>{                             
                if(response.data != null){ 
                    console.log(response);
                        setMasterData(response.data);
                        setChecked(response.data.allow_followers);
                    if(chained === true){
                        LoadFollowerList(quantityPerPage, page, periodically);  
                    }   
                    else{
                        setNonPeriodicFlag(false);
                        setPeriodicFlag(false);
                        setLoading(false); //Stops loading spinning
                    }                
                }
                else{
                }
            })
            .catch(error => {                         
                if(error.response.data.message === "Login is required"){
                    AlertMessage("follower.Session finished, please log in again", "error", "red", "");
                    setLoading(false); //Stops loading spinning 
                    console.log(error);
                    navigate(baseEnv + "/");
                }
                else{
                    if(chained === true){
                        LoadFollowerList(quantityPerPage, page, periodically);
                    }
                    else{
                        setNonPeriodicFlag(false);
                        setPeriodicFlag(false);
                    }                     
                    if(!periodically) AlertMessage("signalProvider.Server error, try again", "error", "red", ""); 
                    setLoading(false); 
                    console.log(error);
                }
            });
        }
        else{
            LoadFollowerList(quantityPerPage, page, periodically, true);
        }
                                      
    };

    //Function in charge of making the follower list request of the signal provider to the communitraders api
    const LoadFollowerList = async (quantity, page, periodically, chained) => {
        if(periodically === false){
            setLoading(true);
            if(periodicFlag === false){
                setNonPeriodicFlag(true);
            }
            else if(chained === false){
                await wait(1000);
                setNonPeriodicFlag(true);
            }           
        }
        else{
            setLoading(loading);
        }
        //periodically === false ? setLoading(true) : setLoading(loading);
        signalService.getSlaves(
            quantity,
            page,
            headers
        )
        .then(response=>{
            return response.data;
        })
        .then(response=>{
            if(chained === true){
                LoadSignalHistoricalSettings(quantityHistoricalPerPage, pageHistorical, periodically, true);
            }
            else{
                setNonPeriodicFlag(false);
                setPeriodicFlag(false);
                setLoading(false); //Stops loading spinning
            }              
            if(response.status === "Success"){ 
                setFollowerList(response.data.list);
                setTotalRows(response.data.total_registers);
            }
            else{
                if(!periodically) AlertMessage("signalProvider.Server error, try again", "error", "red", ""); 
                console.log("Endpoint get master status OK but not info"); 
                console.log(response);
            }
        })
        .catch(error => {
                if(error.response.data.message === "Login is required"){
                    AlertMessage("follower.Session finished, please log in again", "error", "red", "");
                    setLoading(false); //Stops loading spinning 
                    setNonPeriodicFlag(false);
                    setPeriodicFlag(false);
                    console.log(error);
                    navigate(baseEnv + "/");
                }
                else{
                    if(chained === true){
                        LoadSignalHistoricalSettings(quantityHistoricalPerPage, pageHistorical, periodically, true);
                    }
                    else{
                        setNonPeriodicFlag(false);
                        setPeriodicFlag(false);
                        setLoading(false); //Stops loading spinning  
                    }
                    if(!periodically) AlertMessage("signalProvider.Server error, try again", "error", "red", ""); 
                    console.log(error);
                }
            }         
        );                              
    };

    //Function in charge of making the historical list request of the signal provider to the communitraders api
    const LoadSignalHistoricalSettings = async (quantity, page, periodically, chained) => {
        if(periodically === false){
            setLoading(true);
            if(periodicFlag === false){
                setNonPeriodicFlag(true);
            }
            else if(chained === false){
                await wait(1000);
                setNonPeriodicFlag(true);
            }           
        }
        else{
            setLoading(loading);
        }
        //periodically === false ? setLoading(true) : setLoading(loading);
        signalService.masterHistoricalSettings(
            quantity,
            page,
            headers
        )
        .then(response=>{
            setLoading(false); 
            return response.data;
        })
        .then(response=>{
            if(response.status === "Success"){ 
                setLoading(false); 
                setNonPeriodicFlag(false);
                setPeriodicFlag(false); 
                setHistoricalList(response.data.list);
                setTotalHistoricalRows(response.data.total_registers);
                console.log("Endpoint get historical list status OK");
                console.log(response.data);
            }
            else{
                setLoading(false); 
                setNonPeriodicFlag(false);
                setPeriodicFlag(false); 
                if(!periodically) AlertMessage("signalProvider.Server error, try again", "error", "red", ""); 
                console.log("Endpoint get historical list status OK but not info"); 
                console.log(response);
            }
        })
        .catch(error => {
                if(error.response.data.message === "Login is required"){
                    AlertMessage("follower.Session finished, please log in again", "error", "red", "");
                    setLoading(false); //Stops loading spinning 
                    setNonPeriodicFlag(false);
                    setPeriodicFlag(false); 
                    console.log(error);
                    navigate(baseEnv + "/");
                }
                else{
                    setLoading(false); 
                    setNonPeriodicFlag(false);
                    setPeriodicFlag(false); 
                    if(!periodically) AlertMessage("signalProvider.Server error, try again", "error", "red", ""); 
                    console.log(error);
                }
            }         
        );                              
    };

    //Function in charge of making the follower setting information request who is following the signal provider in communitraders api
    const LoadFollowerSettings = async (id) => {
        setLoading(true);
        if(periodicFlag === false){
            setNonPeriodicFlag(true);
        }
        else{
            await wait(1000);
            setNonPeriodicFlag(true);
        }   
        signalService.getFollowerInformation(
            {
                account: id
            },
            headers
            //Login request body
        )
        .then(response=>{
            return response.data;
        })
        .then(response=>{
            if(response.data != null){ 
                setLoading(false); 
                setNonPeriodicFlag(true);
                setFollowerData(response.data);
            }
            else{
                setLoading(false); 
                setNonPeriodicFlag(true);
                console.log("Endpoint follower data status ok but not data");
                AlertMessage("signalProvider.Server error, try again", "error", "red", "");  
            }
        })
        .catch(error => {
            if(error.response.data.message === "Login is required"){
                AlertMessage("follower.Session finished, please log in again", "error", "red", "");
                setLoading(false); //Stops loading spinning 
                setNonPeriodicFlag(true);
                console.log(error);
                navigate(baseEnv + "/");
            }
            else{
                AlertMessage("signalProvider.Server error, try again", "error", "red", ""); 
                setLoading(false); 
                setNonPeriodicFlag(true);
                console.log(error);
            }
        });                              
    };

    //Function in charge of making the setting information update request of the signal provider to the communitraders api
    const UpdateSignalData = async (params, type = "") => {
        setLoading(true);
        if(periodicFlag === false){
            setNonPeriodicFlag(true);
        }
        else{
            await wait(1000);
            setNonPeriodicFlag(true);
        }  
        signalService.editMaster(
            headers,
            params
        )
        .then(response=>{
            setLoading(false); 
            return response.data;
        })
        .then(response=>{
            if(response.status === "Success"){ 
                setLoading(false); 
                setNonPeriodicFlag(false);
                switch (type) {
                    case "visibility off":
                        AlertMessage("signalProvider.Visibility deactivated", "success", "green", "");                      
                        setChecked(false);
                        break;
                    case "visibility on":
                        AlertMessage("signalProvider.Visibility activated", "success", "green", "");
                        setChecked(true);
                        break;
                    case "drawdownOn":
                        AlertMessage("signalProvider.Disable account in max drawdown activated", "success", "green", "");
                        setMasterData(response.data);
                        break;
                    case "drawdownOff":
                        AlertMessage("signalProvider.Disable account in max drawdown deactivated", "success", "green", "");
                        setMasterData(response.data);
                        break;
                    case "settings":
                        AlertMessage("signalProvider.Signal provider information was successfully edited", "success", "green", "");
                        setMasterData(response.data);
                        break;
                    case "":
                        setMasterData(response.data);
                        LoadSignalStatus(false);
                        break;
                    default:
                        break;
                        
                }                
            }
            else{
                setLoading(false); 
                setNonPeriodicFlag(false);
                AlertMessage("signalProvider.Server error, try again", "error", "red", "")
            }
        })
        .catch(error => {
            if(error.response.data.message === "Login is required"){
                AlertMessage("follower.Session finished, please log in again", "error", "red", "");
                setLoading(false); //Stops loading spinning 
                setNonPeriodicFlag(false);
                console.log(error);
                navigate(baseEnv + "/");
            }
            else if(error.response.data.message === "The strategy name cannot be used as it belongs to another signal provider"){
                setLoading(false); 
                setNonPeriodicFlag(false);
                console.log(error);
                AlertMessage("signalProvider.The name of the strategy is already being used by another signal provider", "error", "red", "");
            }
            else{
                setLoading(false); 
                setNonPeriodicFlag(false);
                console.log(error);
                AlertMessage("signalProvider.Server error, try again", "error", "red", "");
            }
        });                              
    };

    //Function in charge of making the delete user as signal provider request to the communitraders api
    const deleteSignalProvider = async () => {
        setLoading(true);
        if(periodicFlag === false){
            setNonPeriodicFlag(true);
        }
        else{
            await wait(1000);
            setNonPeriodicFlag(true);
        } 
        signalService.deleteMaster(
            headers
        )
        .then(response=>{
            setLoading(false); 
            return response.data;
        })
        .then(response=>{
            if(response.status === "Success"){ 
                setLoading(false); 
                setNonPeriodicFlag(false);
                swal.fire({
                    title: t("signalProvider.Deleted"),
                    text: t("signalProvider.The Signal Provider account has been deleted"),
                    icon: 'success',
                    confirmButtonColor: "#3085d6"
                })
                setChecked(false);
                logOut();
                navigate(baseEnv + "/");
            }
            else{
                setLoading(false); 
                setNonPeriodicFlag(false);
                AlertMessage("signalProvider.Server error, try again", "error", "red", "");
            }
        })
        .catch(error => {
            if(error.response.data.message === "Login is required"){
                AlertMessage("follower.Session finished, please log in again", "error", "red", "");
                setLoading(false); //Stops loading spinning 
                setNonPeriodicFlag(false);
                console.log(error);
                navigate(baseEnv + "/");
            }
            else if(error.response.data.message === "Signal provider cannot be deleted because it has open orders"){
                AlertMessage("signalProvider.delete error", "error", "red", error.response.data.data.quantity, "signalProvider.open trades");
                setLoading(false); //Stops loading spinning
                setNonPeriodicFlag(false);
                console.log(error); 
            }
            else{
                setLoading(false); 
                setNonPeriodicFlag(false);
                console.log(error);
                AlertMessage("signalProvider.Server error, try again", "error", "red", "");
            }
        });                              
    };

    //Function in charge of making the reactivate signal account in case of deactivation request of the signal provider to the communitraders api
    const reactivateSignalProvider = async() => {
        setLoading(true);
        if(periodicFlag === false){
            setNonPeriodicFlag(true);
        }
        else{
            await wait(1000);
            setNonPeriodicFlag(true);
        } 
        signalService.reactivateAccount(
            headers
        )
        .then(response=>{
            setLoading(false); 
            return response.data;
        })
        .then(response=>{
            if(response.status === "Success"){ 
                AlertMessage("signalProvider.Signal Provider account reactivate", "success", "green", ""); 
                UpdateSignalData({disable_account_on_drawdown: false});
            }
            else{
                setLoading(false); 
                setNonPeriodicFlag(false);
                AlertMessage("signalProvider.Server error, try again", "error", "red", "");
            }
        })
        .catch(error => {
            if(error.response.data.message === "Login is required"){
                AlertMessage("follower.Session finished, please log in again", "error", "red", "");
                setLoading(false); //Stops loading spinning 
                setNonPeriodicFlag(false);
                console.log(error);
                navigate(baseEnv + "/");
            }
            else{
                setLoading(false); 
                setNonPeriodicFlag(false);
                console.log(error);
                AlertMessage("signalProvider.Server error, try again", "error", "red", "");
            }
        });                              
    };

    //Function in charge of making the log out request of the signal provider to the communitraders api
    async function logOut() {
        setLoading(true);
        if(periodicFlag === false){
            setNonPeriodicFlag(true);
        }
        else{
            await wait(1000);
            setNonPeriodicFlag(true);
        } 
        userService.logOut( //Calls the method stopFollowing from follower service (axios request), its passed the headers
            headers
        )
        .then(response=>{ //Waits for a response from the axios request
            return response.data;
        })
        .then(response=>{
            setLoading(false);
            setNonPeriodicFlag(false);
            if(response.status === "Success"){                   
                console.log("Log out Successful");
            }
            else{
                console.log("Log out without response");
            }
        })
        .catch(error => {
                setLoading(false);
                setNonPeriodicFlag(false);
                console.log(error);
            }         
        ); 
        navigate(baseEnv + "/");
    }

   
    //-----------------------------------------------------------
    //Functions and validations area
    //-----------------------------------------------------------
   
    //Validates if errorMes state is true on each render, if so, its shown a modal with an error message
    if(errorMes === true){
        AlertMessage("signalProvider.Server error, try again", "error", "red", "")
        setErrorMes(false);
    };
    
    //Function handler calls when user changes the visibility switch
    function handleChange() {
        
        
        if (checked) { //Validates if visibility state was activate or deactivate before user changed the switch
            //Ask the user if they are sure to disable visibility
            swal.fire({
                title: t("signalProvider.Are you sure to disable visibility?"),
                text: t("signalProvider.Visibility off"),
                icon: "warning",
                iconColor: "orange",
                color: "#363636",
                background: "#FFFFFF",
                showCancelButton: true,
                cancelButtonText: t("signalProvider.Cancel"),
                cancelButtonColor: "#3085d6",
                confirmButtonText: t("signalProvider.Continue"),
                confirmButtonColor: "#FF0000"
            }).then((result) => {
                if (result.isConfirmed) { //If user confirm it's called the request function to update signalProvider data to change the visibility to false
                    UpdateSignalData({
                        allow_followers: false
                    }, "visibility off");                    
                } else if (result.dismiss) {
                    swal.fire({
                        title: t("follower.Cancelled"),
                        icon: 'error',
                        confirmButtonColor: "#3085d6"
                    })
                }
            });
        }
        else {
            //Ask the user if they are sure to disable visibility
            swal.fire({
                title: t("signalProvider.Are you sure to enable visibility?"),
                text: t("signalProvider.Visibility on"),
                icon: "warning",
                iconColor: "orange",
                color: "#363636",
                background: "#FFFFFF",
                showCancelButton: true,
                cancelButtonText: t("signalProvider.Cancel"),
                cancelButtonColor: "#3085d6",
                confirmButtonText: t("signalProvider.Continue"),
                confirmButtonColor: "#FF0000"
            }).then((result) => {
                if (result.isConfirmed) { //If user confirm it's called the request function to update signalProvider data to change the visibility to true
                    UpdateSignalData({
                        allow_followers: true
                    }, "visibility on");                   
                } else if (result.dismiss) {
                    swal.fire({
                        title: t("follower.Cancelled"),
                        icon: 'error',
                        confirmButtonColor: "#3085d6"
                    })
                }
            });
        }
    };

    // Function handler calls when user clicks on sidebar button, this function change the left sidebar state to the opposite, Used to tell the css whether or not to show the sidebar
    function toggleSidebar() {
        setLeftOpen(!leftOpen);
    };


    //Function handler calls when user clicks on an sidebar option, this allows css whether or not shows the followers table or historical settings table
    function  TriggerMenu() {
        setTrig(!trig);
        setFollowTrig(false);              
        if (window.innerWidth <= 550) { //if view width is lower than 550 pixels, the sidebar is hidden
            setLeftOpen(false);
        }  
        if(!trig){
            LoadSignalData(false, false);
        }
    }

    //Function handlers user when signal provider user clicks on view follower settings, this allows signal provider views the follower setting,
    function TriggerFollowerSetting(id) {
        setIdFollower(id); //Sets the follower id that was click by signal provider
        setFollowTrig(true);  
        setTrig(false);
        LoadFollowerSettings(id); //Calls the function in charge to handler whether or not to show that form
    }

    //Function handlers triggered when signal user presses on Follower list or Historical List, this function controls what components is rendered
    function triggerTables(table) {
        if (window.innerWidth <= 550) { //If view width is lower than 550 pixels, the sidebar is hidden
            setLeftOpen(false);
        }
        if (table === "follower") {
            setFollowerOpen(true);
            setHistoryOpen(false);
        }
        else if (table === "history") {
            setHistoryOpen(true);
            setFollowerOpen(false);
        }

    }

    //Function handler calls when signal providers clicks on settings close button on signal settings form, the state is send by props at childen component
    function SettingCloseHandle() {
        setTrig(false);
    }

    //Function handler calls when signal providers clicks on settings close button on follower settings form, the state is send by props at childen component
    function SettingFollowerCloseHandle() {
        setFollowTrig(false); 
    };

    //Function handler calls when signal user clicks on change amount of items per page in follower list
    const handlePerRowsChange = (newPerPage, page) => {
        LoadFollowerList(newPerPage, page, false, false);
		setQuantityPerPage(newPerPage);
	};

    //Function handler calls when signal provider clicks on change page in next or back page in follower list
    const handlePageChange = page => {
        LoadFollowerList(quantityPerPage, page, false, false);
        setPage(page);  
	};

    //Function handler calls when signal user clicks on change amount of items per page in historical settings list
    const handlePerRowsHistoricalChange = (newPerPage, page) => {
        LoadSignalHistoricalSettings(newPerPage, page, false, false);
		setQuantityHistoricalPerPage(newPerPage);
	};

    //Function handler calls when signal provider clicks on change page in next or back page in historical list
    const handlePageHistoricalChange = page => {
        LoadSignalHistoricalSettings(quantityHistoricalPerPage, page, false, false);
        setHistoricalPage(page);  
	};

    function AlertMessage(message, icon, color, quantity = "", quantityText = "") {       
        swal.fire({
            text: t(message) + quantity + t(quantityText),
            icon: icon,
            iconColor: color,
            color: "#363636",
            background: "#FFFFFF",
            confirmButtonColor: "#FF0000"
        });
    };

    //-------------------------------------------------------------------------------------------
    //Periodically request
    //-------------------------------------------------------------------------------------------

    // Initial request use when the component is rendered for the first time
      useEffect(() => {
        LoadSignalStatus(false); //Main request function is called in here with a false argument that indicates tha the call is no periodically
        //getIp();
    }, []);

    // Periodically main request use each certain time, it works the same than the first effect, the different is this is called periodically
      useInterval(() => {
        LoadSignalStatus(true); 
      }, 10000)


        return (
            <div id='layout'>
                <Loading loading={loading}/>
                <div id='left' className={leftOpen2}>
                
                    <div className='icon'
                        onClick={toggleSidebar} >
                        &equiv;
                    </div>
                    <div className={`sidebar ${leftOpen2}`} >
                        <div className='header'>
                            <img
                                className='imageTitle'
                                src={logo}
                                alt="Logo"
                                htmlFor="logo-image"
                            ></img>
                        </div>
                        <div className='content'>
                            <div className='userData'>
                                {t(title)}
                                <div className='userLogin'>
                                    <img
                                        className='imageLogin'
                                        src={PersonIcon}
                                        alt="Person"
                                        htmlFor="person-icon"
                                    ></img>
                                    <div> 
                                        {masterStatus.name != null ? masterStatus.name : "No data"} <br></br><font color="gray">{user}</font>
                                    </div>
                                </div>
                                <div className='userLogin'>
                                    {t("signalProvider.Visibility")}
                                    <Switch
                                        className="react-switch"
                                        checked={checked}
                                        onChange={handleChange}
                                        offColor="#B71C1C"
                                        onColor="#43A047"
                                        onHandleColor="#FAFAFA"
                                        offHandleColor="#FAFAFA"
                                        handleDiameter={15}
                                        uncheckedIcon={false}
                                        checkedIcon={false}
                                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                        height={15}
                                        width={30}
                                        id="material-switch"
                                    ></Switch>
                                    <div className='labelField'>
                                        <Tooltip title={t("signalProvider.When visibility is activated, the Signal Provider user can be seen by followers and start copying him in his operations")}>
                                        <img
                                            className='imageTitle'
                                            src={InfoIcon}
                                            alt="info"
                                            htmlFor="info-icon"
                                            data-tip data-for="infoTooltip"
                                        ></img>
                                        </Tooltip>
                                    </div>
                                </div>
                            </div>
                            <div className='userSettings'>
                                <div className='labelField'>
                                    <button className={`
                                        setting
                                        ${followerOn}
                                    `} onClick={(e) => triggerTables('follower')}> {t("signalProvider.Followers")} </button>
                                    <Tooltip title={t("signalProvider.List of users who are following the Signal Provider account")}>
                                        <img
                                            className='imageTitle'
                                            src={InfoIcon}
                                            alt="info"
                                            htmlFor="info-icon"
                                            data-tip data-for="infoTooltip"
                                        ></img>
                                     </Tooltip>
                                </div>
                                <div className='labelField'>
                                    <button className='setting' onClick={TriggerMenu}> {t("signalProvider.My Settings")} </button>
                                    <Tooltip title={t("signalProvider.Signal Provider account settings menu")}>
                                    <img
                                        className='imageTitle'
                                        src={InfoIcon}
                                        alt="info"
                                        htmlFor="info-icon"
                                        data-tip data-for="infoTooltip"
                                        ></img>
                                    </Tooltip>
                                </div>
                                <div className='labelField'>
                                <button className={`
                                    setting
                                    ${historyOn}
                                `} onClick={(e) => triggerTables('history')}> {t("signalProvider.Historical Settings")} </button>
                                    <Tooltip title={t("signalProvider.Log of settings made by the user Signal Provider")}>
                                    <img
                                        className='imageTitle'
                                        src={InfoIcon}
                                        alt="info"
                                        htmlFor="info-icon"
                                        data-tip data-for="infoTooltip"
                                        ></img>
                                    </Tooltip>
                                </div>                                
                                <button className='setting' onClick={logOut}> {t("signalProvider.Sign Out")} </button>                                
                            </div>
                        </div>
                    </div>
                </div>

                <div className={`
                      main
                      ${'left-' + leftOpen2}
                  `} id='main'>
                    <div className='header'>
                        <h3 className={`
                      title
                      ${'left-' + leftOpen2}
                  `}>
                            <div className='statusData'>
                                <div>
                                    {masterStatus.name != null ? masterStatus.name : "No data"}                               
                                </div>
                                <div>
                                    {t("signalProvider.Balance")}   {masterStatus.balance != null ? masterStatus.balance : "no balance"} 
                                </div>
                                <div>
                                    {t("signalProvider.Equity")}    {masterStatus.equity != null ? masterStatus.equity : "no equity"} 
                                </div>
                            </div>
                            <button className='reloadIcon' onClick={(e) =>LoadSignalStatus(false)}>
                                <img
                                    src={reload}
                                    alt="reload Icon"
                                    htmlFor="reload-icon" 
                                />
                            </button>
                        </h3>
                    </div>
                    <div className='contentMain'>                     
                        <SettingForm 
                        triggerMenu={trig} 
                        onClose={SettingCloseHandle} 
                        checkedVisibility={checked} 
                        masterData={masterData} 
                        setLoading={setLoading} 
                        UpdateSignalData={UpdateSignalData}
                        deleteSignalProvider={deleteSignalProvider}
                        reactivateSignalProvider={reactivateSignalProvider}
                        masterStatus={masterStatus}
                        />
                        <FollowerSettingForm 
                        idFollower={idFollower} 
                        triggerFollowerSettings={followTrig} 
                        onClose={SettingFollowerCloseHandle}
                        followerData={followerData}
                        />
                        <SignalFollowesTable 
                        triggerFollower={followerOpen} 
                        onOpenSetting={TriggerFollowerSetting} 
                        followerList={followerList} 
                        totalRows={totalRows} 
                        handlePerRowsChange={handlePerRowsChange} 
                        handlePageChange={handlePageChange} 
                        />
                        <SignalLogsTable 
                        triggerSignalHistory={historyOpen}
                        historicalList={historicalList}
                        totalHistoricalRows={totalHistoricalRows}
                        handlePerRowsHistoricalChange={handlePerRowsHistoricalChange}
                        handlePageHistoricalChange={handlePageHistoricalChange}
                        />
                        
                    </div>
                </div>
            </div>
        )
}

export default Sidebar;