//Disclouser component rendered when the user gets login correctly

import classes from "../../css/LoginForm.module.scss";
import { useNavigate, Navigate } from "react-router-dom";
import Cookies from "universal-cookie";
import IdleTimerContainer from "../../config/iddleTimerContainer";
import { useTranslation } from "react-i18next";
import { baseEnv} from "../../App";
import userService from "../../services/user";



function Disclouser(props) {
    //Declaration of constants, variables and hooks
    const navigate = useNavigate();
    const cookies = new Cookies();
    const user = cookies.get("user");
    const userType = cookies.get("account type");
    const [t] = useTranslation("global");
    const token = cookies.get("token"); 
    let headers = { //Headers preparation, the token is get from cookies, the same token obtained when the user log in
        "Content-type": "application/json; charset=UTF-8",
        "Authorization": "Bearer " + token
    };

    //This validation protects the view in case someone access the route without do the login correctly
    if (user == null) {
        return <Navigate to={baseEnv + "/"}/> //Return the use to the login view
    }

    //Function in charge of making the log out request of the signal provider to the communitraders api
    function logOut() {
        userService.logOut( //Calls the method stopFollowing from follower service (axios request), its passed the headers
            headers
        )
        .then(response=>{ //Waits for a response from the axios request
            return response.data;
        })
        .then(response=>{
            if(response.status === "Success"){                   
                console.log("Log out Successful");
            }
            else{
                console.log("Log out without response");
            }
        })
        .catch(error => {
                console.log(error);
            }         
        ); 
        navigate(baseEnv + "/");
    }

    // Function to handle the user's action in disclouser view, depending of the user type account, this one is redirect to signal o follower view
    function DisclouserAgree(event) {  
        if (event === "true") {
            console.log(userType);
            switch (userType) {
                case "signal":
                    navigate(baseEnv + "/SignalProvider");
                    break;

                case "follower":
                    navigate(baseEnv + "/Follower");
                    break;

                default:
                    break;
            }
        }
        else {
            //If user select the option disagree, the user is redirect to login screen
            logOut();
        }
    }
    
    return (
        
        <div>
            <IdleTimerContainer></IdleTimerContainer> 
            <form className={classes.DisclouserForm} id="Disclouserform" >              

                <div className={classes.DisclouserText}>
                    <title className={classes.DisclouserTitle}>{t("disclouser.CommuniTraders Acknowledgement and Agreement")}</title>
                    {t("disclouser.Disclouser text")}
                </div>

                <div className={classes.ActionPanel}>
                    <button
                        className={classes.agreeButton}
                        type="button"
                        id="agree-botom"
                        value="true"
                        onClick={ (e) => DisclouserAgree(e.target.value) }
                    >
                        {t("disclouser.I Agree")}
                    </button>     
                    <button
                        className={classes.loginBtnDisclouser}
                        type="button"
                        id="desagree-botom"
                        value="false"
                        onClick={(e) => DisclouserAgree(e.target.value)}
                    >
                        {t("disclouser.I Disagree")}
                    </button>  

                </div>

            </form>
        </div>
    );
}

export default Disclouser;